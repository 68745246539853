import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Row } from 'antd';

import { GET_VISITS } from "graphql/query/visit-qql";

import { TableRender } from "utils";
import VisitsExport from "components/visit/visits-export";
import LocationVisitsFilters from "./location-visits-filters";
import locationVisitsTableBuilder from "./location-visits-table-builder";


const LocationsVisitsTab = ( { location } ) => {

    const history = useHistory();

    let match = useRouteMatch( `/locations/${ location.id }/visits/page/:currentPage` );

    const basePath = `/locations/${ location.id }/visits`;

    const defaultOrderBy = { column: "CREATED_AT", order: "DESC" };
    const currentPage = match ? parseInt( match.params.currentPage ) : 1;
    const perPage = 50;
    const locationId = { 
        column: "LOCATION_ID",
        operator: "EQ",
        value: location.id 
    };

    const [ searchText, setSearchText ] = useState( "" );
    const [ orderBy, setOrderBy ] = useState( [ defaultOrderBy ] );
    const [ filters, setFilters ] = useState( locationId );
    const [ total, setTotal ] = useState();

    const { data : { visits = {} } = {}, loading } = useQuery( GET_VISITS, {
        variables: {
            text: searchText,
            first: perPage,
            page: currentPage,
            orderBy,
            where: { ...filters }
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
    } );
    
    const { paginatorInfo = {} } = visits;

    useEffect( () => {

        if( ( paginatorInfo && !total ) || ( paginatorInfo.total >= 0 && paginatorInfo.total !== total ) ){
            setTotal( paginatorInfo.total );
            history.replace( basePath );
        }

    }, [ paginatorInfo, total, history, basePath ] );

    return (
        <>
            <div className="table-header p-3 mt-5">

                <Row className="title-header mb-4">

                    <h3>Visits</h3>

                    <VisitsExport searchText={ searchText } filters={ filters } />

                </Row>

                <Row className="filter-header">
                    
                    <LocationVisitsFilters setFilters={ setFilters } setSearchText={ setSearchText } location={ location } />

                    <span className="float-right">total: { !total ? '0' : total }</span>

                </Row>

            </div>

            <TableRender 
                tableBuilder={ locationVisitsTableBuilder } 
                data={ visits?.data }
                loading={ loading }
                setOrderBy={ setOrderBy }
                defaultOrder={ defaultOrderBy }
                setFilters={ setFilters }
                pagination={{
                    pageSize: perPage,
                    total,
                    hideOnSinglePage: ( !total ) / ( total - perPage < 0 ),
                    current: currentPage,
                    onChange: ( page ) => {
                        let path = page === 1 ? `/locations/${ location.id }/visits` : `/locations/${ location.id }/visits/page/${ page }`
                        history.push( path );
                    }
                }}
            />
        </>
    );
}

export default LocationsVisitsTab;