import { gql } from '@apollo/client';

export const VISITS_EXPORT = gql`
    mutation VisitsExport(
        $text: String,
        $orderBy: [MutationVisitsExportOrderByOrderByClause!],
        $where: MutationVisitsExportWhereWhereConditions,
    ) {
        visitsExport(
            text: $text,
            orderBy: $orderBy,
            where: $where
        ){
            label
            message
            file
        }
    }
`;