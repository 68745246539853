import React from "react";
import { Link } from "react-router-dom";

const LocationEditLinkField = ( { location } ) => {

    return(
        <Link to={ `/locations/${ location.id }` }>edit</Link>
    );

};

export default LocationEditLinkField;