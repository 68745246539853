import React from 'react';
import { Link } from "react-router-dom";
import { UserTag } from 'components/user/helpers'; 
import { LocationTitleLinkTooltip } from "components/location/helpers";


const VisitUser = ( { visit } ) => {

    return(
        <>
            <h3 className="text-center mt-2">User { visit.user_id ? 'registered' : 'a guest' }</h3>
            
            <div className="short-info-user">

                <p>
                    <strong>Visit ID:</strong>
                    <span className="first-span">
                        { visit.id }
                    </span>
                </p>

                <p>
                    <strong>Full name:</strong>
                    <span className="first-span">
                        { visit.first_name } { visit.last_name ?? '' } 
                        { visit.user ? <Link to={`/users/${ visit.user.id }`}> [edit]</Link> : '' } 
                    </span>
                </p>

                <p><strong>Email:</strong><span className="first-span">{ visit.email }</span></p>
                <p><strong>phone:</strong><span className="first-span">{ visit.phone ?? 'n/a' }</span></p>

                <p>
                    <strong>status:</strong>
                    <span className="first-span">
                        { visit.user ? <UserTag >{ visit.user.status }</UserTag> : 'n/a' }
                    </span>
                </p>

                <p>
                    <strong>role:</strong>
                    <span className="first-span">
                        { visit.user ? <UserTag >{ visit.user.role }</UserTag> : 'n/a' }
                    </span>
                </p>

                <p><strong>IP:</strong><span className="first-span">{ visit.remote_addr }</span></p>
                <p><strong>Date visit:</strong><span className="first-span">{ visit.created_at }</span></p>

                <p>
                    <strong>Location:</strong>
                    <span className="first-span">
                        <LocationTitleLinkTooltip location={ visit.location } />
                    </span>
                </p>

                <p>
                    <strong>Place:</strong>
                    <span className="first-span">
                        { visit.place_id ? '' : 'n/a' }
                    </span>
                </p>

                <p>
                    <strong>number guests:</strong>
                    <span className="first-span">
                        { visit.number_guests ? visit.number_guests : 'n/a' }
                    </span>
                </p>

            </div>           
        </>
    );

};

export default VisitUser;