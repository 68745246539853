import React from "react";
import { useMutation } from "@apollo/client";

import { Form, Button, Radio  } from 'antd';
import UserAutoSelectSearch from 'components/user/forms/user-autoselect-search';

import { USER_UPDATE } from "graphql/mutation/user-gql";
import { GET_USERS } from "graphql/query/user-gql";

import { errorNotification, successNotification } from "components/request-result";

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const CompanyAddUserForm = ( { company, variables } ) => {

    const [ form ] = Form.useForm();

    const [userUpdateRequest, { loading }] = useMutation( USER_UPDATE,
        {
            update(cache, { data }) {

                const {
                    userUpdate : {
                        user
                    }
                } = data;

                const { users } = cache.readQuery({
                    query: GET_USERS,
                    variables
                });

                cache.writeQuery({
                    query: GET_USERS,
                    variables,
                    data: {
                        users: {
                            ...users,
                            paginatorInfo:{
                                ...users.paginatorInfo,
                                total: users.paginatorInfo.total + 1
                            },
                            data : [
                                user,
                                ...users.data
                            ]
                        }
                    },
                });

                successNotification({
                    title: 'Success',
                    description: 'User added to company'
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <Form
            key="company-add-user"
            form={ form }
            initialValues={{
                role: 'owner'
            }}
            
            onFinish={ ( values ) => {
                
                userUpdateRequest({
                    variables: {
                        input: {
                            company_id: company.id,
                            ...values
                        }
                    }

                }).catch((error) => {
                    errorNotification(error);
                });

            }}
            {...layout} 
            >

            <div className="field-group">

                <Form.Item 
                    name="id"
                    label="User" 
                    initialValue=""
                    rules={[
                        { required: true, message: 'Please select User' }
                    ]}
                >
                    
                    <UserAutoSelectSearch company={ company } />
              
                </Form.Item>

            </div>

            <div className="field-group">

                <Form.Item 
                    name="role"
                    label="Role"
                    rules={[
                        { required: true }
                    ]}
                >
                    <Radio.Group >

                        <Radio.Button key="owner" value="owner" >
                            OWNER
                        </Radio.Button>

                        <Radio.Button key="staff" value="staff" >
                            STAFF
                        </Radio.Button>

                    </Radio.Group>
                </Form.Item>

            </div>

            <div className="form-actions mt-3 text-right">
                <Button
                    loading={ loading }
                    disabled={ loading }
                    type="primary"
                    htmlType="submit"
                >
                    Add
                </Button>
            </div>

            

        </Form>
    );

};

export default CompanyAddUserForm;

