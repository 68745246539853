import React from 'react';
import { useQuery } from "@apollo/client";
import { Layout } from 'antd';
// import { StarOutlined, StarFilled, StarTwoTone } from '@ant-design/icons';
import AppHeader from "components/app-header";
import Sidebar from 'components/sidebar';
import AppBreadcrumb from 'components/app-breadcrumb';

import { GET_APP_PARAMS } from "graphql/query/local-store-gql";

import { AppRoutes } from "components/routes";
import { Suspense } from "components/request-result";
import { useMe } from "components/use-hooks";
// import Icons from "components/icons";

import './app.scss';

const { Sider, Footer } = Layout;

const App = () => {

    const { loading, error } = useMe("cache-and-network", true),
        { data : { appParams } } = useQuery( GET_APP_PARAMS );

    return (
        <div className={ `app ${ appParams.appBlockClass.join(" ") }`}>
            <Suspense type="block" state={ { loading, error } }>
                <AppHeader key="app-header"/>
                <Layout key="app-layout" >
                    <Sider className="sidebar-left">
                        <Sidebar appParams={ appParams } />
                    </Sider>
                    <Layout>
                        <AppBreadcrumb />
                        <main>
                            <AppRoutes />
                        </main>
                        <Footer style={{ textAlign: 'center' }}>Loresta ©2021</Footer>
                    </Layout>
                </Layout>
                
            </Suspense>
           
        </div>
    );
};

export default App;