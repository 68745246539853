import React from "react";
import { useMutation } from "@apollo/client";
import { Form, Button, Select } from 'antd';

import { TRANSLATION_SYNC } from "graphql/mutation/translate-gql";

import { useLanguage } from "components/use-hooks";
import { errorNotification, successNotification } from "components/request-result";

const { Option } = Select;

const translationType = [
    // { text: 'app', value: 'app' },
    // { text: 'crm', value: 'crm' },
    // { text: 'adm', value: 'adm' },
    { text: 'core', value: 'core' }
];


const TranslateFormGenerateJson = ( { setJsonFile } ) => {

    const { languages } = useLanguage();

    const [ form ] = Form.useForm();

    const [translationsSyncRequest, { loading }] = useMutation( TRANSLATION_SYNC,
        {
            update(cache, { data }) {

                const {
                    translationsSync : {
                        label,
                        message,
                        jsonFile
                    }
                } = data;

                setJsonFile( jsonFile );

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }

    );
    
    return(
        <Form
            key="translates-generate-json"
            className="d-flex"
            form={ form }
            onFinish={ ( { type, language_slug } ) => {
                translationsSyncRequest({
                    variables: {
                        language_slug, 
                        type
                    }
                }).catch((error) => {
                    errorNotification(error);
                });
            }}
        >
            
            <Form.Item 
                initialValue="en"
                name="language_slug"
                label="Language"
            >
                <Select key="language-slug">

                    { languages && languages.map( ( { slug, label } ) => {

                        return <Option key={ slug } value={ slug }>{ label }</Option>;

                    })}

                </Select>
            </Form.Item>

            <Form.Item 
                name="type"
                label="Type"
                initialValue="core"
                className="ms-3"
            >
                <Select key="type" disabled>
                    { translationType.map( ( { text, value }, index ) => (

                        <Option disabled={ value !== 'core' } key={ index } value={ value }>{ text }</Option>
                        
                    ))}
                </Select>
            </Form.Item>

            
            <Button
                loading={ loading }
                disabled={ loading }
                onClick={ () => form.submit() }
                // htmlType="submit"
            >
                Generate json file
            </Button>
        </Form>
    );

};


export default TranslateFormGenerateJson;