import React, { useState, useEffect } from "react";
import { Input } from 'antd';

import CompanyAutoSelectSearch from "components/company/forms/company-autoselect-search";
import { LocationsStatusFilter, LocationsTypeFilter } from "../filters";
import { gqlBuilderWhere } from "utils";


const { Search } = Input;

const LocationsTableFilter = ( { setFilters, setSearchText } ) => {

    const [ filterList, setFilterList ] = useState({
        companyId : {},
        status : {},
        type: {},
    });

    useEffect( () => {
        setFilters( gqlBuilderWhere( filterList ) );
    }, [filterList, setFilters] );


    return (
        <>
            <Search 
                className="search-header w-100"
                placeholder="any search"
                onSearch={ ( value ) => setSearchText( value ) }  
            />

            <LocationsTypeFilter setFilterList={ setFilterList } filterList={ filterList } />

            <CompanyAutoSelectSearch 
                onSelect={ ( value ) => {

                    setFilterList( { 
                        ...filterList,
                        companyId : {
                            column: "COMPANY_ID",
                            operator: "IN",
                            value: [value]
                        }
                    })

                }} 
                onClear={ () => {
                    setFilterList( { 
                        ...filterList,
                        companyId : {},
                    })
                }}
            />

            <LocationsStatusFilter setFilterList={ setFilterList } filterList={ filterList } />

        </>
    );

};

export default LocationsTableFilter;