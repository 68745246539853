import { gql } from '@apollo/client';

export const GET_TRANSLATION_FILE_JSON = gql`
    query getTranslationsFileJsonQuery(
        $language_slug: String!
    ){
        translationsFileJsonQuery(
            language_slug: $language_slug,
        ){
            label
            message
            jsonFile
        }
    }
`;

export const GET_TRANSLATIONS = gql`
    query getTranslations(
        $text: String,
        $first: Int,
        $page: Int,
        $language_slug: String
        $child_slug: String
        $type: String
        $tr_group: String
        $orderBy: [QueryTranslationsOrderByOrderByClause!],
    ){
        translations(
            text: $text,
            first: $first,
            page: $page,
            language_slug: $language_slug,
            type: $type
            tr_group: $tr_group
            orderBy: $orderBy,
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data{
                id
                language_slug
                type
                tr_group
                tr_key
                value
                childTranslation(
                    child_slug: $child_slug
                ){
                    id
                    language_slug
                    type
                    tr_group
                    tr_key
                    value
                    created_at
                    updated_at 
                }
                created_at
                updated_at
            }
        }
    }
`;