import React from 'react';

import { DrawerStandart } from 'components/service';
import Forms from 'components/company/forms'


const CompanyUsersAddDrawer = ( { company, variables } ) => {

    return(
        
        <DrawerStandart 
            title="Adding a User to the Company" 
            buttonText="Add User"
            buttonType="primary"
        >
           <Forms.CompanyAddUserForm company={ company } variables={ variables } />

        </DrawerStandart> 
        
    );

}

export default CompanyUsersAddDrawer;