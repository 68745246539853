import React from "react";
import { Card, Col, Row, Skeleton } from 'antd';

import { useGeneralWidgets, useActiveMenuItem } from "components/use-hooks";
import { SystemInfo } from "components/widgets";
import { PageWrapDefault } from "components/wraps";

import './home.scss';


const HomeAppPage = () => {

    useActiveMenuItem( ["dashboard"] );

    const { generalWidgets, loading } = useGeneralWidgets(true);
    
    return(
        <PageWrapDefault className="page-dashboard">
            <Row gutter={16} className="mb-3">
                <Col span={12}>
                    <Card className="widget system-info-widgets" type="inner" title="system information" bordered={true}>
                        { !loading &&
                            <SystemInfo generalWidgets={ generalWidgets } typeKey={ 'system_info' } />
                        }

                        { loading && <Skeleton active paragraph={{ rows: 9 }} /> }
                    </Card>
                </Col>
                <Col span={12}>
                    <Card className="widget site-api-widgets" type="inner" title="Sites & API" bordered={true}>
                        { !loading &&
                            <SystemInfo generalWidgets={ generalWidgets } typeKey={ 'site_api' } />
                        }
                        { loading && <Skeleton active paragraph={{ rows: 7 }} /> }
                        {/* { generalWidgets && generalWidgets.site_api.map( ( { label, info }, index ) => (

                            <Descriptions key={`system-info-widget-${index}`} size="default">
                                <Descriptions.Item label={ label }>{ info }</Descriptions.Item>
                            </Descriptions>

                        ))}

                        { !generalWidgets && <Skeleton active paragraph={{ rows: 9 }} /> } */}

                    </Card>
                </Col>
                {/* <Col span={8}>
                    <Card type="inner" title="Card title" bordered={true}>
                    Card content
                    </Card>
                </Col> */}
            </Row>
            {/* <Row gutter={16} className="mb-3">
                <Col span={8}>
                    <Card type="inner" title="Card title" bordered={true}>
                    Card content
                    </Card>
                </Col>
                <Col span={8}>
                    <Card type="inner" title="Card title" bordered={true}>
                    Card content
                    </Card>
                </Col>
                <Col span={8}>
                    <Card type="inner" title="Card title" bordered={true}>
                    Card content
                    </Card>
                </Col>
            </Row> */}

        </PageWrapDefault>
    )
};

export default HomeAppPage;