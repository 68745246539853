import React, { useState, useEffect } from 'react';
import { Menu } from 'antd';
import { Link } from "react-router-dom";

import { NoticeFormsNewCount } from 'components/notice-forms/notice-forms-count';
import './sidebar.scss';


const { SubMenu } = Menu;

const Sidebar = ( { appParams }) => {

    let {
        selectedKeys = ['dashboard'],
        openKeys     = ['dashboard']
    } =  appParams.activeMenu;

    const [ openSubMenu, setOpenSubMenu ] = useState( openKeys );

    useEffect( () => {

        setOpenSubMenu(openKeys)

    }, [appParams.activeMenu, openKeys] );

    return( 
        <>
            <h5 className="sidebar-header text-center mt-2">Navigation</h5>
            <Menu
                mode="inline"
                selectedKeys={ selectedKeys }
                openKeys={ openSubMenu }
                onOpenChange={ ( keys ) => setOpenSubMenu(keys)}
                style={{ borderRight: 0 }}
            >
                <Menu.Item key="dashboard" >
                    <Link to="/">Dashboard</Link>
                </Menu.Item>

                <SubMenu key="users" title="Users">
                    <Menu.Item key="users-all"><Link to="/users">All Users</Link></Menu.Item>
                    <Menu.Item key="user-add-new"><Link to="/users/create">Add New</Link></Menu.Item>
                </SubMenu>
                
                <SubMenu key="companies" title="Companies">
                    <Menu.Item key="companies-all"><Link to="/companies">All Companies</Link></Menu.Item>
                    <Menu.Item key="company-add-new"><Link to="/companies/create">Add New</Link></Menu.Item>
                </SubMenu>

                <SubMenu key="locations" title="Locations">
                    <Menu.Item key="locations-all"><Link to="/locations">All Locations</Link></Menu.Item>
                    <Menu.Item key="location-add-new"><Link to="/locations/create">Add New</Link></Menu.Item>
                </SubMenu>

                <SubMenu key="visits" title="Visits">
                    <Menu.Item key="visits-all"><Link to="/visits">All Visits</Link></Menu.Item>
                </SubMenu>

                <Menu.Item key="notice-forms"><NoticeFormsNewCount /></Menu.Item>
                
                <Menu.Item key="languages"><Link to="/languages">Languages</Link></Menu.Item>

                <SubMenu key="translations" title="Translations">
                    <Menu.Item key="translations-all"><Link to="/translations">All Translations</Link></Menu.Item>
                    <Menu.Item key="generate-json"><Link to="/translations/generate-json">Generate json</Link></Menu.Item>
                </SubMenu>

            </Menu>
        </>
    );
};

export default Sidebar;