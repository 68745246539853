import { gql } from '@apollo/client';

export const LOCATION_CREATE = gql`
    mutation LocationCreate(
        $type: LocationType!, 
        $company_id: ID!,
        $input: LocationCreateOrUpdateInput
    ) {
        locationCreate(
            type: $type, 
            company_id: $company_id,
            input: $input
        ){
            label
            message
            location{
                id
                title
                company_id
                type
                status
                logo
                preview_banner
                latitude
                longitude
                slug
                qrcode
                start_at
                end_at
                created_at
                updated_at
                meta_fields{
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
                company{
                    id
                    title
                    status
                    logo
                }
            }
        }
    }
`;

export const LOCATION_DELETE = gql`
    mutation LocationDelete(
        $id: ID!
    ) {
        locationDelete(
            id: $id
        ){
            id
            title
        }
    }
`;


export const LOCATION_UPDATE = gql`
    mutation LocationUpdate(
        $id : ID!
        $locationInput : LocationCreateOrUpdateInput
        $metaInput : [LocationMetaInput]
    ){
        locationUpdate(
            id : $id
            input : $locationInput
        ){
            label
            message
        }
        locationMetaSet(
            location_id : $id
            input : $metaInput
        ){
            label
            message
            location{
                id
                title
                company_id
                type
                status
                logo
                preview_banner
                latitude
                longitude
                slug
                qrcode
                start_at
                end_at
                created_at
                updated_at
                meta_fields{
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
                company{
                    id
                    title
                    status
                    logo
                }
            }
        }
    }
`;

export const LOCATION_META_UPLOAD = gql`
    mutation LocationMetaUpload(
        $input : LocationUploadFileInput
    ) {
        locationMetaUpload(
            input : $input
        ){
            label
            message
            location{
                id
                logo
                preview_banner
                meta_fields{
                    id
                    location_id
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
            }
        }
    }
`;

