import React from "react";
import { Skeleton } from "antd";

import { tableHelper } from "utils";
import UserFields from "components/user/fields";
import { CompanyUserDeleteButton } from "components/company/company-button";
import { CompanyUserDatailsDrawer } from "components/company/company-drawer";


const companyUsersTableBuilder = ({
        loading,
        rowAmount = 5,
        data = [...Array(rowAmount)],
        onlyHeader,
        company,
        variables
    }) => {


    const tableData = data.map( ( row = {}, index ) => {

        return {
            key: row.id ? row.id : `row_index_${index}`,
            id : {
                title : "ID",
                value : row.id,
                columnIndex:'ID',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "5%"
            },
            user_info : {
                title : "User Info",
                // value : <UserFields.Info userInfo={ { id, first_name, last_name, phone, email } }/>,
                value : <CompanyUserDatailsDrawer user={ row } />,
                columnIndex:'LAST_NAME',
                sorter: true,
                empty: <>
                    <Skeleton.Input className="table-skeleton three-quarters" active />
                    <Skeleton.Input className="table-skeleton full" active />
                </>,
                width: "25%"
            },
            role : {
                title : "role",
                value : <UserFields.Role user={ row } />,
                columnIndex:'ROLE',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            status : {
                title : "status",
                value : <UserFields.Status user={ row } />,
                columnIndex:'CREATED_AT',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            action : {
                title : "action",
                value : <CompanyUserDeleteButton company={ company } user={ row } variables={ variables } />,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            }
        };
    });

    return tableHelper.normalize( tableData, loading, onlyHeader );

}

export default companyUsersTableBuilder;