import React from "react";
import { useHistory } from "react-router-dom";
import { PageHeader } from 'antd';

import Forms from "components/company/forms";
import { PageWrapDefault } from "components/wraps";
import { useActiveMenuItem } from "components/use-hooks";

const CompanyCreatePage = () => {
    
    let history = useHistory();
    
    useActiveMenuItem( ["company-add-new"], ["companies"] );
   
    return(
        <PageWrapDefault className="page-company-create single">
            
            <PageHeader
                className="site-page-header"
                onBack={ () => history.push( `/companies` ) }
                title="Add new Company"
            />
            <div className="single-content">
                <Forms.CompanyCreateForm company={ {} } />
            </div>

        </PageWrapDefault>
    );


};


export default CompanyCreatePage;