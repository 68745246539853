import React from "react";
import { useMutation } from "@apollo/client";
import { Form, Input, Button, Select } from 'antd';

import { userRoles, userStatus } from "components/user/helpers";

import { USER_UPDATE } from "graphql/mutation/user-gql";
import { GET_USER } from "graphql/query/user-gql";
import { errorNotification, successNotification } from "components/request-result";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const UserEditForm = ( { user } ) => {

    const [ form ] = Form.useForm();

    const [userUpdateRequest, { loading }] = useMutation( USER_UPDATE,
        {
            update(cache, { data }) {

                const {
                    userUpdate : {
                        label,
                        message,
                        user
                    }
                } = data;

                cache.writeQuery(  {
                    query: GET_USER,
                    data: {
                        user: {
                            ...user
                        }
                    }
                });

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <>
            <h3>Edit Profile</h3>
            <Form
                key="edit-user"
                initialValues={ {
                    first_name: user?.first_name,
                    last_name: user?.last_name,
                    email: user?.email,
                    phone: user?.phone,
                    role: user?.role,
                    status: user?.status,
                    language_slug: user?.language_slug
                } }
                form={ form }
                onFinish ={ ( values ) => {
                    userUpdateRequest({
                        variables: {
                            input: {
                                id: user.id,
                                ...values
                            }
                        }
                    }).catch( ( error ) => {
                        errorNotification( error );
                    });
                }}
                {...layout} className="edit-user">

                <div className="field-group">
                    
                    <Form.Item 
                        name="first_name"
                        label="First Name" 
                        rules={[
                            { required: true, message: 'Please input First name' }
                        ]}
                    >
                        <Input name="first_name" />
                    </Form.Item>

                    <Form.Item 
                        name="last_name"
                        label="Last Name" 
                        rules={[
                            { required: true, message: 'Please input Last name' }
                        ]}
                    >
                        <Input name="last_name" />
                    </Form.Item>

                </div>

                <div className="field-group">

                    <Form.Item 
                        name="email"
                        label="Email" 
                        rules={[{ type: 'email', required: true }]}
                    >
                        <Input name="email" />
                    </Form.Item>

                    <Form.Item 
                        name="phone"
                        label="Phone"
                    >
                        <Input name="phone" />
                    </Form.Item>

                </div>

                <div className="field-group">
                    <Form.Item 
                        name="status"
                        label="Status">
                        <Select key="user-status">
                            { userStatus.map( ( { text, value }, index ) => (
        
                                <Option key={ index } value={ value }>{ text }</Option>
                                
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item 
                        name="role"
                        label="Role">
                        <Select key="user-role" >
                        
                            {/* { userRoles.map( ( { text, value }, index ) => (

                                <Option key={ index } value={ value }>{ text }</Option>
                                
                            ))} */}


                        { userRoles.map( ( { text, value }, index ) => {

                            if( ( user.role === 'admin' || user.role === 'developer' ) && ( value === 'owner' || value === 'staff' || value === 'visitor' ) ){

                                return (
                                    <Option disabled key={ index } value={ value }>{ text }</Option>
                                )

                            } else {

                                return (
                                    <Option key={ index } value={ value }>{ text }</Option>
                                )

                            }

                        })}

                        </Select>
                    </Form.Item>
                </div>

                <div className="form-actions mt-5">
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={ loading }
                    >
                        Update
                    </Button>
                </div>

            </Form>
        </>
    );

};

export default UserEditForm;