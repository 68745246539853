import React from "react";
import { Typography } from 'antd';
import { useMutation } from "@apollo/client";

import { TRANSLATION_UPDATE } from "graphql/mutation/translate-gql";
import { errorNotification, successNotification } from "components/request-result";


const { Paragraph } = Typography;

const TranslateValueField = ( { childSlug, translationParrent, translationChild } ) => {

    const { value = "" } = translationChild;

    const [translationUpdate] = useMutation( TRANSLATION_UPDATE,
        {
            update(cache, { data }) {

                const {
                    translationUpdate : {
                        label,
                        message,
                        translation
                    }
                } = data;

                cache.modify({
                    id: cache.identify( translationParrent ),
                    fields: {
                        childTranslation(){
                            return { __ref: `${ translation.__typename }:${ translation.id }`};
                        }
                    },
                });


                successNotification({
                    title: label,
                    description: message
                });
                
            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <>
            <Paragraph 
                editable={{ onChange: ( value ) => {
                    translationUpdate({
                        variables: {
                            child_slug: childSlug,
                            type: translationParrent.type,
                            tr_group: translationParrent.tr_group,
                            tr_key: translationParrent.tr_key,
                            value: value
                        }
                    }).catch((error) => {
                        errorNotification(error);
                    });
                } }}
            >
                { value } 
            </Paragraph>
            
        </>
    );

} 

export default TranslateValueField;