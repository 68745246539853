import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Popconfirm } from 'antd';

import { GET_USERS } from "graphql/query/user-gql";
import { COMPANY_USER_DELETE } from "graphql/mutation/company-gql";

import { errorNotification, successNotification } from "components/request-result";


const CompanyUserDeleteButton = ( { user, company, variables } ) => {

    const [visible, setVisible] = React.useState(false);

    const [companyUserDeleteRequest, { loading }] = useMutation( COMPANY_USER_DELETE,
        {
            update(cache, { data }) {

                const {
                    companyUserDelete : {
                        label,
                        message
                    }
                } = data;
                
                const { users } = cache.readQuery({
                    query: GET_USERS,
                    variables
                });
            
                cache.writeQuery({
                    query: GET_USERS,
                    variables,
                    data: {
                        users: {
                            ...users,
                            paginatorInfo:{
                                ...users.paginatorInfo,
                                total: users.paginatorInfo.total - 1
                            },
                            data : [
                                ...users.data.filter( ( item ) =>  item.id !== user.id)
                            ]
                        }
                    },
                });

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }

    );

    return(
        <>
            <Popconfirm
                title={ <>Are you sure you want to remove the <b>{ user.first_name } { user?.last_name }</b>, from the <b>{ company?.title }</b></> }
                visible={ visible }
                onConfirm={ () => {
                        companyUserDeleteRequest({
                            variables: {
                                id: company.id,
                                user_id: user.id
                            }
                        })
                    }
                }
                okButtonProps={ { loading } }
                onCancel={ () => setVisible( false ) }
                >
                <Button 
                    key="company-user-delete"
                    danger
                    type="text"
                    onClick={ () => setVisible( true ) }
                >
                    Remove
                </Button>
            </Popconfirm>
        </>
    );

};

export default CompanyUserDeleteButton;