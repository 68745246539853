import LocationTitleLinkField from "./location-title-link-field";
import LocationEditLinkField from "./location-edit-link-filed";
import LocationStatusField from "./location-status-field";

const LocationFields = {
    TitleLink : LocationTitleLinkField,
    EditLink : LocationEditLinkField,
    StatusField : LocationStatusField
}

export default LocationFields;