import React, { useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Skeleton, Table, Input, Empty, Row } from 'antd';

import { GET_NOTICE_FORMS } from "graphql/query/notice-form-gql";
import { noticeFormsTableColumns, noticeFormsData, NoticeFiltersHelper } from "../helpers";


const { Search } = Input;

const NoticeAlarmTable = () => {

    const history = useHistory();

    const defaultOrderBy = { column: "CREATED_AT", order: "DESC" };
    const perPage = 50;

    let match = useRouteMatch( '/notice-forms/page/:currentPage' );

    const [ searchText, setSearchText ] = useState ( "" );
    const [ orderBy, setOrderBy ] = useState ( [ defaultOrderBy ] );
    const [ filters, setFilters ] = useState();

    const currentPage = match ? parseInt( match.params.currentPage ) : 1;

    const { data : { noticeForms } = {}, loading } = useQuery( GET_NOTICE_FORMS, {
        variables: {
            text: searchText,
            first: perPage,
            page: currentPage,
            orderBy,
            where: { ...filters }
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
    } );
    
    const { paginatorInfo } = noticeForms ?? {};

    return(
        <>
            <div className="table-header p-3">

                <Row className="title-header mb-4">

                    <h1>Corona incident reported</h1>

                </Row>

                <Row className="filter-header">
                    
                    <Search 
                        className="search-header w-100"
                        placeholder="any search"
                        onSearch={ ( value ) => setSearchText( value ) }  
                    />

                    <NoticeFiltersHelper setFilters={ setFilters } />

                    <span className="float-right">total: { !noticeForms ? '0' : noticeForms.paginatorInfo.total }</span>

                </Row>

            </div>

            <Table
                locale={{
                    emptyText: loading ? <Skeleton active={true} paragraph={{ rows: 30 }} /> : <Empty />
                }}

                onChange={ ( pagination, filters, { column, order } ) => {
                   
                    if( column && order ){
                        
                        setOrderBy([
                            { 
                                column: column.columnIndex, 
                                order: order === "ascend" ? "ASC" : "DESC"  
                            }
                        ])

                    } else { setOrderBy( [ defaultOrderBy ] ) }

                }}
                columns={ noticeFormsTableColumns }
                dataSource={ noticeFormsData( noticeForms?.data ) }
                tableLayout='fixed'
                pagination={{
                    pageSize: paginatorInfo?.perPage,
                    total: paginatorInfo?.total,
                    hideOnSinglePage: paginatorInfo?.total / paginatorInfo?.perPage > 1,
                    current: currentPage,
                    onChange: ( page ) => {
                        let path = page === 1 ? `/notice-forms` : `/notice-forms/page/${ page }`
                        history.push( path );
                    }
                }}
            />
        </>
    );


}

export default NoticeAlarmTable;