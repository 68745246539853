import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "antd";
import { UploadOutlined } from '@ant-design/icons';

import { LOCATION_META_UPLOAD } from "graphql/mutation/location-gql";

import LocationMetaDeleteButton from "./location-meta-delete-button";
import { UploadFile } from "components/service";
import { successNotification } from "components/request-result";
import { fileNamefromUrl } from "utils";

const LocationMetaUpload = ( { location, accept = 'file', metaKey, metaFileUrl, title = '' } ) => {


    const [metaUpload, { loading }] = useMutation( LOCATION_META_UPLOAD, {
            update( cache, { data }) {

                const {
                    locationMetaUpload : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });
            }
    });
    
    return(
        <>
            <UploadFile
                accept={ accept }
                variables={{
                    input: {
                        meta_key : metaKey,
                        location_id : location.id
                    }
                }}
                uploadMutation={ metaUpload }
                loading={ loading }
            >
                <Button icon={<UploadOutlined />} disabled={ loading }>Upload</Button>
                
            </UploadFile>
        
            <b className="ms-2">{ title }</b>

            { metaFileUrl && 
                <p>
                    <a rel="noreferrer" target="_blank" className="ms-3 me-2" href={ metaFileUrl }>{ fileNamefromUrl( metaFileUrl ) }</a>
                    <LocationMetaDeleteButton location={ location } filename={ fileNamefromUrl( metaFileUrl ) } metaKey="menu_main" />
                </p>
            }
        </>
       
    );
};

export default LocationMetaUpload;