import React from "react";
import { Link } from "react-router-dom";

import { CompanyBadge, CompamyTitleTooltip } from "components/company/helpers";

const UserCompanyField = ( { company } ) => {

    if( !company ){
        return null;
    }

    return(
        <p className="d-flex">
            <CompanyBadge>{ company?.status }</CompanyBadge> 
            <Link className="ellipsis" to={`/companies/${ company.id }`}>
                <CompamyTitleTooltip company={ company } />
            </Link>
        </p>
    );

};

export default UserCompanyField;