import { gql } from '@apollo/client';


export const GET_COMPANIES = gql`
    query GetCompanies(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QueryCompaniesOrderByOrderByClause!],
        $where: QueryCompaniesWhereWhereConditions
    ){
        companies(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data{
                id
                title
                status
                logo
                email
                business_ID
                created_at
                updated_at
                delete_at
                meta_fields{
                    id
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
                count_employees
                count_locations
            }
            
        }
    }
`;

export const GET_COMPANIES_AUTOCOMPLETE = gql`
    query GetCompaniesAutoComplete(
        $text: String,
        $first: Int
    ){
        companies(
            text: $text,
            first: $first
        ){
            data{
                id
                title
                status
            }
            
        }
    }
`;

export const GET_COMPANY = gql`
    query GetCompany(
        $id: ID,
    ) {
        company(
            id: $id,
        ){
            id
            title
            status
            logo
            business_ID
            email
            created_at
            updated_at
            delete_at
            meta_fields{
                id
                meta_key
                meta_type
                meta_value
                meta_group
            }
            count_employees
            count_locations
            # users(
            #     first:Int
            # ){
            #     paginatorInfo{
            #         total
            #     }
            #     data{
            #         id
            #         first_name
            #         last_name
            #         email
            #         phone
            #         role
            #         status
            #         email
            #     }
            # }
        }
    }
`;

export const GET_COMPANY_USERS = gql`
    query GetCompany(
        $id: ID,
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [CompanyUsersOrderByOrderByClause!],
        $where: CompanyUsersWhereWhereConditions
    ) {
        company(
            id: $id,
        ){
            id
            users(
                text: $text,
                first: $first,
                page: $page,
                orderBy: $orderBy,
                where: $where,
            ){
                paginatorInfo{
                    total
                    count
                    currentPage
                    perPage
                    lastPage
                }
                data{
                    id
                    first_name
                    last_name
                    email
                    phone
                    status
                    role
                    avatar
                    language_slug
                    company_id
                    company {
                        id
                    }
                }
            }
        }
    }
`;
