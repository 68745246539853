import React, { useState } from "react";


const FloatLabel = ({ children, label, value, ...props }) => {

    const [focus, setFocus] = useState(false);

    return (
        <div
            className="label-wrap"
            onBlur={ () => setFocus(false) }
            onFocus={ () => setFocus(true) }
        >
            { React.cloneElement( children, { ...props, value }) }
            <label className={ `label${ focus || (value && value.length !== 0) ? " label-float" : "" }` }>
                { label }
            </label>
        </div>
    );
};

export default FloatLabel;
