import React from "react";
import { Skeleton } from "antd";

import { tableHelper } from "utils";
import UserFields from "../fields"; 


const usersTableBuilder = ({
        loading,
        rowAmount = 15,
        data = [...Array(rowAmount)],
        onlyHeader
    }) => {


    const tableData = data.map( ( row = {}, index ) => {

        const {
            id,
            first_name,
            last_name,
            phone,
            email,
            role,
            status,
            company = {},
            created_at,
            last_visit_at
        } = row;

        return {
            key: id ? id : `row_index_${index}`,
            id : {
                title : "ID",
                value : id,
                columnIndex:'ID',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "5%"
            },
            user_info : {
                title : "User Info",
                value : <UserFields.Info userInfo={ { id, first_name, last_name, phone, email } }/>,
                columnIndex:'LAST_NAME',
                sorter: true,
                empty: <>
                    <Skeleton.Input className="table-skeleton three-quarters" active />
                    <Skeleton.Input className="table-skeleton full" active />
                </>,
                width: "25%"
            },
            role : {
                title : "role",
                value : <UserFields.Role user={ { id, role }  } />,
                columnIndex:'ROLE',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            company : {
                title : "company",
                value : <UserFields.Company company={ company } />,
                columnIndex:'COMPANY_ID',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "30%",
                ellipsis: true
            },
            created_at : {
                title : "created date",
                value : created_at,
                columnIndex:'CREATED_AT',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            last_visit_at : {
                title : "Last visit",
                value : last_visit_at,
                columnIndex:'LAST_VISIT_AT',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            status : {
                title : "status",
                value : <UserFields.Status user={ { id, status } } />,
                columnIndex:'CREATED_AT',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            action : {
                title : "action",
                value : <UserFields.ActionEdit userId={ id } />,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            }
        };
    });

    return tableHelper.normalize( tableData, loading, onlyHeader );

}

export default usersTableBuilder;