import React from "react";
import { Tag } from 'antd';


const userTagColor = ( value ) => {

    switch( value ){

        case 'new':
            return 'orange';

        case 'active':
            return 'success';

        case 'block':
            return 'error';

        case 'close':
                return 'default';

        case 'developer':
            return 'geekblue';

        case 'admin':
            return 'red';

        case 'owner':
            return 'purple';

        default:
            return '';
    }

}

const UserTag = (props) => {

    const { children } = props;

    return(
        <Tag color={ userTagColor( children ) }>{ children?.toUpperCase() }</Tag>
    )
}

export {
    UserTag
}