import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Row } from 'antd';

import { GET_VISITS } from "graphql/query/visit-qql";

import { TableRender } from "utils";
import VisitsExport from "components/visit/visits-export";
import UserVisitsTableFilter from "./user-visits-table-filter";
import userVisitsTableBuilder from "./user-visits-table-builder";


const UserVisitsTable = ( { user } ) => {

    const history = useHistory();

    let match = useRouteMatch( `/users/${ user.id }/visits/page/:currentPage` );

    const basePath = `/users/${ user.id }/visits`;

    const defaultOrderBy = { column: "CREATED_AT", order: "DESC" },
          userId = { column: "USER_ID", operator: "EQ", value: user.id },
          currentPage = match ? parseInt( match.params.currentPage ) : 1,
          perPage = 25;

    const [ searchText, setSearchText ] = useState ( "" );
    const [ orderBy, setOrderBy ] = useState ( [ defaultOrderBy ] );
    const [ filters, setFilters ] = useState( userId );
    const [ total, setTotal ] = useState();

    const { data : { visits = {} } = {}, loading } = useQuery( GET_VISITS, {
        variables: {
            text: searchText,
            first: perPage,
            page: currentPage,
            orderBy,
            where: { ...filters }
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
        //fetchPolicy: 'cache-and-network'
    } );
    
    const { paginatorInfo = {} } = visits;

    useEffect( () => {

        if( ( paginatorInfo && !total ) || ( paginatorInfo.total >= 0 && paginatorInfo.total !== total ) ){
            setTotal( paginatorInfo.total );
            history.replace( basePath );
        }

    }, [ paginatorInfo, total, history, basePath ] );

    return(
        <>
            <div className="table-header p-3">

                <Row className="title-header mb-4">

                    <h3>Visits</h3>

                    <VisitsExport searchText={ setSearchText } filters={ filters } />

                </Row>

                <Row className="filter-header">
                    
                    <UserVisitsTableFilter currentUser={ userId } setFilters={ setFilters } />

                    <span className="float-right">total: { !total ? '0' : total }</span>

                </Row>

            </div>

            <TableRender 
                tableBuilder={ userVisitsTableBuilder }
                data={ visits?.data }
                loading={ loading }
                setOrderBy={ setOrderBy }
                defaultOrder={ defaultOrderBy }
                setFilters={ setFilters }
                pagination={{
                    pageSize: perPage,
                    total,
                    hideOnSinglePage: ( !total ) / ( total - perPage < 0 ),
                    current: currentPage,
                    onChange: ( page ) => {
                        let path = page === 1 ? `/users/${ user.id }/visits` : `/users/${ user.id }/visits/page/${ page }`
                        history.push( path );
                    }
                }}
            />
            
        </>
    );

};


export default UserVisitsTable;