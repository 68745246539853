import React from "react";
import { useActiveMenuItem } from "components/use-hooks";

import { TranslateGenerateJson } from "components/translate";

const TranslatesGenerateJsonPage = () => {

    useActiveMenuItem( ["generate-json"], ["translations"] );

    return(

        <TranslateGenerateJson />
    
    );
};

export default TranslatesGenerateJsonPage;