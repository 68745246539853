import React from 'react';
import { DrawerStandart } from 'components/service';

import { VisitDatailsTabs } from '../visit-tabs';

const VisitDatailsDrawer = ( { visit } ) => {

    return(

        <DrawerStandart
            title="Information" 
            buttonText="details"
            className="drawer-button"
            buttonType="dashed"
        >
            <VisitDatailsTabs visitId={ visit.id } visitEmail={ visit.email } />

        </DrawerStandart> 

    );

};

export default VisitDatailsDrawer;

