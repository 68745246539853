import UserChangePasswordForm from "./user-change-password-form";
import UserEditForm           from "./user-edit-form";
import UserCreateForm         from "./user-create-form";
import UserDeleteForm         from "./user-delete-form";

import './forms.scss';

const Forms = {
    UserChangePassword : UserChangePasswordForm,
    UserEditForm       : UserEditForm,
    UserCreateForm     : UserCreateForm,
    UserDeleteForm     : UserDeleteForm 
};

export default Forms;
