import React from "react";
import { Row } from "antd";

import { LocationMetaUpload } from "components/location/forms/meta";
import { metaFields } from "utils";

const LocationMenuTab = ( { location } ) => {

    const { normalize, getValue } = metaFields;
    const meta = normalize( location.meta_fields );

    const menuMainUrl = getValue( meta, "menu_main" );
    const menuDesertsUrl = getValue( meta, "menu_deserts" );
    const menuBarUrl = getValue( meta, "menu_bar" );
    const menuVineUrl = getValue( meta, "menu_vine" );

    return(
        <>
            <h3 className="mt-4 mb-3">Menu</h3>

            <Row wrap={false} className="mb-4 align-items-center">

                <LocationMetaUpload location={ location } accept={ 'application/pdf' } metaKey="menu_main" metaFileUrl={ menuMainUrl } title="Main Menu" />

            </Row>

            <Row wrap={false} className="mb-4 align-items-center">

                <LocationMetaUpload location={ location } accept={ 'application/pdf' } metaKey="menu_deserts" metaFileUrl={ menuDesertsUrl } title="Deserts Menu" />

            </Row>

            <Row wrap={false} className="mb-4 align-items-center">

                <LocationMetaUpload location={ location } accept={ 'application/pdf' } metaKey="menu_bar" metaFileUrl={ menuBarUrl } title="Bar Menu" />

            </Row>

            <Row wrap={false} className="mb-4 align-items-center">

                <LocationMetaUpload location={ location } accept={ 'application/pdf' } metaKey="menu_vine" metaFileUrl={ menuVineUrl } title="Vine Menu" />

            </Row>

        </>
    );
};

export default LocationMenuTab;