import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from "@apollo/client";

import client from "graphql/client";

import App from 'components/app';
import Auth from "components/auth";
import Router from "components/routes";
import { ScrollToTop } from 'components/service';

import 'antd/lib/style/themes/default.less';
import 'antd/dist/antd.less';
import "scss/default.scss";


ReactDOM.render(
    <ApolloProvider client={ client }>
        <Router>
            <Auth>
                <ScrollToTop />
                <App />
            </Auth>
        </Router>
    </ApolloProvider>,
    document.getElementById('root')
);

