import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Form, Input, Button, Select } from 'antd';

import { companyStatus } from "components/company/helpers";

import { COMPANY_CREATE } from "graphql/mutation/company-gql";
import { errorNotification, successNotification } from "components/request-result";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const CompanyCreateForm = () => {

    let history = useHistory();

    const [ form ] = Form.useForm();

    const [companyCreateRequest, { loading }] = useMutation( COMPANY_CREATE,
        {
            update(cache, { data }) {

                const {
                    companyCreate : {
                        label,
                        message,
                        company
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

                if( data?.companyCreate?.company.id ){

                    history.push( `/companies/${ company.id }` );
                   
                }

            },
            onError(error){
                errorNotification(error);
            }
        }

    );

    return(
        
        <Form
            key="create-company"
            
            form={ form }
            onFinish={(values) => {
                companyCreateRequest({
                    variables: {
                        input: { ...values }
                    }
                }).catch((error) => {
                    errorNotification(error);
                });
            }}
            {...layout} className="create-company">

            <div className="field-group">
                <Form.Item 
                    name={'title'} 
                    label="Title" 
                    rules={[
                        { required: true, message: 'Please input Title' }
                    ]}
                >
                    <Input name="title" />
                </Form.Item>

                <Form.Item 
                    name={'business_ID'} 
                    label="business ID" 
                    rules={[
                        { required: true, message: 'Please input Business ID' }
                    ]}
                >
                    <Input name="business_ID" />
                </Form.Item>

            </div>

            <div className="field-group">

                <Form.Item 
                    name={'status'}
                    label="Status"
                    initialValue="draft"
                    rules={[
                        { required: true, message: 'Please select status' }
                    ]}
                >
                    <Select key="company-status" disabled>
                        {companyStatus.map( ( { text, value }, index ) => (
    
                            <Option key={ index } value={ value }>{ text }</Option>
                            
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item 
                    name={'email'} 
                    label="Email" 
                    rules={ [{ required: true, message: 'Please input email', type: 'email' }] }
                >
                    <Input name="email" />
                </Form.Item>

            </div>

            <div className="form-actions mt-5">
                <Button
                    loading={ loading }
                    disabled={ loading }
                    type="primary"
                    htmlType="submit"
                >
                    Save
                </Button>
            </div>
            

        </Form>
    );

};

export default CompanyCreateForm;