import { Tooltip } from 'antd';
import { TeamOutlined, ShopOutlined } from '@ant-design/icons';


const CompanyCountLocations = ( { company } ) => {

    if( !company ){
        return '';
    }

    const fullText = ( countLocations ) => {

        return `Locations: ${countLocations}`;

    }

    return (
        <>
            <Tooltip className="analytics visits-per-month me-2" title={ fullText( company?.count_locations ) }>
                <ShopOutlined className="me-2" /><span>{ company?.count_locations && company.count_locations }</span>
            </Tooltip>
        </>
    );

};


const CompanyCountEmployees = ( { company } ) => {

    if( !company ){
        return '';
    }

    const fullText = ( countEmployees ) => {

        return `Employees owner: ${countEmployees}`;

    }

    return (
        <>
            <Tooltip className="analytics location-count-employees me-2" title={ fullText( company.count_employees ) }>
                <TeamOutlined className="me-2" /><span>{ company.count_employees }</span>
            </Tooltip>
        </>
    );

};

export{
    CompanyCountEmployees,
    CompanyCountLocations
}