import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Popconfirm } from 'antd';

import { LOCATION_DELETE } from "graphql/mutation/location-gql";
import { errorNotification, successNotification } from "components/request-result";

const LocationDeleteButton = ( { location } ) => {

    let history = useHistory();

    const [visible, setVisible] = useState( false );

    const [locationDeleteRequest, { loading }] = useMutation( LOCATION_DELETE,
        {
            update() {

                successNotification({
                    title: 'Success',
                    description: 'You have deleted the Location'
                });

                history.push( `/locations` );

            },
            onError( error ){
                errorNotification( error );
            }
        }

    );

    return(
        <>
            <Popconfirm
                title={ <>Are you sure you want to delete <b>{ location?.title }</b></> }
                visible={ visible }
                onConfirm={ () => {
                    locationDeleteRequest({
                        variables: {
                            id: location.id
                        }
                    })
                }}
                okButtonProps={ { loading } }
                onCancel={ () => setVisible( false ) }
                >
                <Button 
                    key="location-delete"
                    className="mb-2"
                    danger
                    type="primary"
                    onClick={ () => setVisible( true ) }
                >
                    Delete
                </Button>
            </Popconfirm>
        </>
    );

};

export default LocationDeleteButton;