import React, { useState, useEffect } from "react";
import { useRouteMatch, useHistory, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Row } from 'antd';

import { GET_LOCATIONS } from "graphql/query/location-qql";

import { TableRender } from "utils";
import LocationTableFilter from "./locations-table-filter";
import locationsTableBuilder from "./locations-table-builder";


const LocationsTable = () => {

    const history = useHistory();

    let match = useRouteMatch( '/locations/page/:currentPage' );
    
    const defaultOrderBy = { column: "CREATED_AT", order: "DESC" },
          currentPage = match ? parseInt( match.params.currentPage ) : 1,
          perPage = 25;

    const [ searchText, setSearchText ] = useState ( "" );
    const [ orderBy, setOrderBy ] = useState ( [ defaultOrderBy ] );
    const [ filters, setFilters ] = useState();
    const [ total, setTotal ] = useState();

    const { data : { locations = {} } = {}, loading } = useQuery( GET_LOCATIONS, {
        variables: {
            text: searchText,
            first: perPage,
            page: currentPage,
            orderBy,
            where: { ...filters }
        },
        fetchPolicy: 'cache-and-network'
    } );

    const { paginatorInfo = {} } = locations;

    useEffect( () => {

        if( ( paginatorInfo && !total ) || ( paginatorInfo.total >= 0 && paginatorInfo.total !== total ) ){
            setTotal( paginatorInfo.total );
            history.replace( `/locations` );
        }

    }, [ paginatorInfo, total, history ] );

    return(
        <>
            <div className="table-header p-3">
                <Row className="title-header mb-4">
                    <h1>Locations</h1>

                    <Link
                        className="ant-btn ant-btn-primary"
                        type="primary"
                        to={ '/locations/create' }>
                        New Location
                    </Link>

                </Row>

                <Row className="filter-header">

                    <LocationTableFilter setFilters={ setFilters } setSearchText={ setSearchText } />
                    
                    <span className="float-right">total: { !total ? '0' : total }</span>

                </Row>

            </div>

            <TableRender 
                tableBuilder={ locationsTableBuilder } 
                data={ locations?.data }
                loading={ loading }
                setOrderBy={ setOrderBy }
                defaultOrder={ defaultOrderBy }
                setFilters={ setFilters }
                pagination={{
                    pageSize: perPage,
                    total,
                    hideOnSinglePage: ( !total ) / ( total - perPage < 0 ),
                    current: currentPage,
                    onChange: ( page ) => {
                        let path = page === 1 ? `/locations` : `/locations/page/${ page }`
                        history.push( path );
                    }
                }}
            />

            {/* <Table
                locale={{
                    emptyText: loading ? <Skeleton active={true} paragraph={{ rows: 15 }} /> : <Empty />
                }}
                onChange={ ( pagination, filters, { column, order } ) => {

                    setFilters( gqlWhere( filters ) );
                   
                    if( column && order ){
                        
                        setOrderBy([
                            { 
                                column: column.columnIndex, 
                                order: order === "ascend" ? "ASC" : "DESC"  
                            }
                        ])

                    } else { setOrderBy( [ defaultOrderBy ] ) }

                }}
                columns={ locationTableColumns } 
                dataSource={ locationData( locations?.data ) }
                tableLayout='fixed'
                pagination={{
                    pageSize: paginatorInfo?.perPage,
                    total: paginatorInfo?.total,
                    hideOnSinglePage: paginatorInfo?.total / paginatorInfo?.perPage > 1,
                    current: currentPage,
                    onChange: ( page ) => {
                        let path = page === 1 ? `/locations` : `/locations/page/${ page }`
                        history.push( path );
                    }
                }}
            /> */}

        </>
    );

};


export default LocationsTable;