import { Link } from "react-router-dom";
import { Tooltip } from 'antd';

const CompamyTitleTooltip = ( { company } ) => {

    return(
        <Tooltip className="ellipsis" placement="topLeft" title={ company.title ?? company.id }>{ company.title ?? company.id }</Tooltip>
    );

}

const CompamyTitleLinkTooltip = ( { company } ) => {

    return(
        <Link className="ellipsis" to={`/companies/${ company.id }`}>
            <CompamyTitleTooltip company={ company } />
        </Link>
    );

}

const companyStatus = [
    { text: 'draft', value: 'draft' },
    { text: 'active', value: 'active' },
    { text: 'block', value: 'block' },
    { text: 'closed', value: 'closed' }
];

export {
    companyStatus,
    CompamyTitleTooltip,
    CompamyTitleLinkTooltip
}