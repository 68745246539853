import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Form, Input, Button, Select } from 'antd';

import { locationType, locationStatus } from "../../helpers";
import CompanyAutoSelectSearch from "components/company/forms/company-autoselect-search";

import { LOCATION_CREATE } from "graphql/mutation/location-gql";
import { errorNotification, successNotification } from "components/request-result";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const LocationCreateForm = () => {

    let history = useHistory();
    const [ form ] = Form.useForm();

    const [locatioCreateRequest, { loading }] = useMutation( LOCATION_CREATE,
        {
            update(cache, { data }) {

                const {
                    locationCreate : {
                        label,
                        message,
                        location
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

                if( data?.locationCreate?.location.id ){

                    history.push( `/locations/${ location.id }` );
                   
                }

            },
            onError(error){
                errorNotification(error);
            }
        }

    );

    return(
        <>
            <Form
                key="create-location-form"
                
                form={ form }
                onFinish={ ( { type, company_id, ...values } ) => {
                    locatioCreateRequest({
                        variables: {
                            type, 
                            company_id,
                            input: { ...values }
                        }
                    }).catch((error) => {
                        errorNotification(error);
                    });
                }}
                {...layout} className="create-location">

                <div className="field-group">
                    
                    <Form.Item 
                        name={['title']} 
                        label="Title" 
                        rules={[
                            { required: true, message: 'Please input Title' }
                        ]}
                    >
                        <Input name="title" />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            { required: true, message: 'Please select Company' }
                        ]}
                        name="company_id"
                        label="Company"
                        initialValue=""
                    >
                        <CompanyAutoSelectSearch />

                    </Form.Item>
                    
                </div>

                <div className="field-group">

                    <Form.Item
                        name="status"
                        label="Status"
                        initialValue="draft"
                        rules={[
                            { required: true, message: 'Please select status' }
                        ]}
                    >
                        <Select key="location-status" disabled>
                            { locationStatus.map( ( { text, value }, index ) => (
        
                                <Option key={ index } value={ value }>{ text }</Option>
                                
                            )) }
                        </Select>
                    </Form.Item>

                    <Form.Item 
                        name="type"
                        label="Type"
                        initialValue="restaurant"
                        rules={[
                            { required: true, message: 'Please select Type' }
                        ]}
                    >
                        <Select key="location-type">
                            { locationType.map( ( { text, value }, index ) => (
        
                                <Option disabled={ value !== 'restaurant' } key={ index } value={ value }>{ text }</Option>
                                
                            )) }
                        </Select>
                    </Form.Item>

                </div>

                <div className="form-actions mt-5">
                    <Button
                        loading={ loading }
                        disabled={ loading }
                        type="primary"
                        htmlType="submit"
                    >
                        Save
                    </Button>
                </div>
               
            </Form>
        </>
    );

};

export default LocationCreateForm;
