import React from "react";
import { Select } from 'antd';

const { Option } = Select;

const VisitsFilterGuestAuth = ( { setFilterList, filterList } ) => {

    return (
        <div className="visits-filter-guest-auth">
            <span className="me-2">Guest or Auth :</span>
            <Select
                className="select-user-auth"
                onChange={ ( value ) => {

                    if( value !== 'all' ){

                        setFilterList( { 
                            ...filterList,
                            userId : {
                                column: "USER_ID",
                                operator: value
                            },
                        })

                    } else {

                        setFilterList( { 
                            ...filterList,
                            userId : {},
                        })

                    }
                }}
                defaultValue="all"
            >
                <Option value="all">All</Option>
                <Option value="IS_NOT_NULL">Register users</Option>
                <Option value="IS_NULL">Guest users</Option>
            </Select>
        </div>
    );

};

export default VisitsFilterGuestAuth;