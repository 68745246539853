import React from "react";
import { useMutation } from "@apollo/client";
import { Row } from "antd";

import { LOCATION_META_UPLOAD } from "graphql/mutation/location-gql";

import { AvatarUpload } from "components/avatar";
import { successNotification } from "components/request-result";
// import { metaFields } from "utils";

const LocationUploadFile = ( { location, ...props } ) => {

    // const { normalize, getValue } = metaFields;
    // const meta = normalize( location.meta_fields );

    const [metaUpload, { loading }] = useMutation( LOCATION_META_UPLOAD, {
            update(cache, { data }) {

                const {
                    locationMetaUpload : {
                        label,
                        message,
                        // location : {
                        //     logo
                        // }
                    }
                } = data;

                // form.setFieldsValue({ "logo-image-unset" : logo });

                successNotification({
                    title: label,
                    description: message
                });
            }
    });

    return(
        <>
            <h3 className="mt-4 mb-3">Media</h3>
        
            <h4><b>Logo</b></h4>
            <Row wrap={false} className="mb-4">
                
                <AvatarUpload
                    uploadMutation={ metaUpload }
                    variables={{
                        input: {
                            meta_key : "logo",
                            location_id : location.id
                        }
                    }}
                    image={ location.logo }
                    loading={ loading }
                    uploadButtonText={ "Upload Logo" }
                />

            </Row>

            <h4><b>Preview banner</b></h4>
            <Row wrap={false} className="mb-4">
               
                <AvatarUpload
                    uploadMutation={ metaUpload }
                    variables={{
                        input: {
                            meta_key : "preview_banner",
                            location_id : location.id
                        }
                    }}
                    image={ location.preview_banner }
                    loading={ loading }
                    uploadButtonText={ "Upload Banner" }
                    extraClass="preview-banner"
                />

            </Row>
        </>
    );
};

export default LocationUploadFile;