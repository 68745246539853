import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { META_DELETE } from "graphql/mutation/meta-qql";

import { errorNotification, successNotification } from "components/request-result";

const LocationMetaDeleteButton = ( { location, filename, metaKey } ) => {

    const [visible, setVisible] = React.useState( false );

    const [locationMetaDeleteRequest, { loading }] = useMutation( META_DELETE,
        {
            update( cache, { data } ) {

                
                const {
                    metaDelete : {
                        label,
                        message
                    }
                } = data;

                cache.evict( { id: cache.identify(
                    location.meta_fields.find( ( field ) => field.meta_key === metaKey )
                )});
                cache.gc();

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError( error ){
                errorNotification( error );
            }
        }

    );

    return(
        <>
            <Popconfirm
                title={ <>Are you sure you want to delete <b>{ filename }</b></> }
                visible={ visible }
                onConfirm={ () => {
                    locationMetaDeleteRequest({
                        variables: {
                            meta_model: 'location',
                            meta_model_id: location.id,
                            meta_key: metaKey,
                            meta_group: 'menus'
                        }
                    })
                }}
                okButtonProps={ { loading } }
                onCancel={ () => setVisible( false ) }
                >
                    <Button 
                        icon={<DeleteOutlined />}
                        disabled={ loading }
                        key="location-meta-delete"
                        danger
                        type="text"
                        onClick={ () => setVisible( true ) }
                    >
                    </Button>
            </Popconfirm>
        </>
    );

};

export default LocationMetaDeleteButton;