import React from "react";
import { Checkbox, Dropdown, Button, } from 'antd';

import { DownOutlined, CheckSquareOutlined, BorderOutlined } from '@ant-design/icons';


const userRoles = [
    { label: 'developer', value: 'developer' },
    { label: 'admin', value: 'admin' },
    { label: 'owner', value: 'owner' },
    { label: 'staff', value: 'staff' },
    { label: 'visitor', value: 'visitor' }
];

const UserRoleFilter = ( { filterList, setFilterList} ) => {

    const UserRoleCheckbox = () => {

        return(
            <div className="dropdown-filter-group">
                <Checkbox.Group 
                    options={ userRoles } 
                    value={ filterList.role.value } 
                    onChange={ ( checkedValues ) => {
            
                        if( checkedValues.length > 0 ){
            
                            setFilterList( { 
                                ...filterList,
                                role : {
                                    column: "ROLE",
                                    operator: "IN",
                                    value: checkedValues
                                }
                            })
            
                        } else {
            
                            setFilterList( { 
                                ...filterList,
                                role : {},
                            })

                        }
            
                    }}  
                />
                <Button 
                    className="mt-2"
                    danger type="link"
                    onClick={ () => {

                        setFilterList( { 
                            ...filterList,
                            role : {},
                        })

                    }}
                >
                    Reset
                </Button>
            </div>
        );

    };

    return(

        <Dropdown 
            overlay={ <UserRoleCheckbox />} 
            placement="bottomCenter" 
            arrow
        >
            <Button>
                { filterList.role.value ? <CheckSquareOutlined /> : <BorderOutlined /> }
                Role 
                <DownOutlined />
            </Button>
        </Dropdown>
        
    );
    
};

export default UserRoleFilter;