import SignInForm from "./sign-in-form";
import ForgotPasswordForm from "./forgot-password-form";
import ChangeForgotPasswordForm from "./change-forgot-password-form";

import "./auth-forms.scss";


const Forms = {
    SignIn : SignInForm,
    ForgotPassword: ForgotPasswordForm,
    ChangeForgotPassword : ChangeForgotPasswordForm
}

export default Forms;