import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Form, Input, Button, Select } from 'antd';

import { userRoles, userStatus } from "components/user/helpers";

import { USER_CREATE } from "graphql/mutation/user-gql";
import { errorNotification, successNotification } from "components/request-result";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const UserCreateForm = () => {

    let history = useHistory();

    const [ form ] = Form.useForm();

    const [userCreateRequest, { loading }] = useMutation( USER_CREATE,
        {
            update(cache, { data }) {

                const {
                    userCreate : {
                        label,
                        message,
                        user
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

                if( data?.userCreate?.user.id ){

                    history.push( `/users/${ user.id }` );
                   
                }

            },
            onError(error){
                errorNotification(error);
            }
        }

    );

    return(
        <>
            <Form
                key="create-user"
                form={ form }
                onFinish={(values) => {
                    userCreateRequest({
                        variables: {
                            input: { ...values }
                        }
                    }).catch((error) => {
                        errorNotification(error);
                    });
                }}
                {...layout} className="create-user">

                <div className="field-group">
                    <Form.Item 
                        name={['first_name']} 
                        label="First Name" 
                        rules={[
                            { required: true, message: 'Please input First name' }
                        ]}
                    >
                        <Input name="first_name" />
                    </Form.Item>

                    <Form.Item 
                        name={['last_name']} 
                        label="Last Name" 
                        rules={[
                            { required: true, message: 'Please input Last name' }
                        ]}
                    >
                        <Input name="last_name" />
                    </Form.Item>

                </div>

                <div className="field-group">

                    <Form.Item 
                        name={['email']} 
                        label="Email" 
                        rules={[{ type: 'email', required: true }]}
                    >
                        <Input name="email" />
                    </Form.Item>

                    <Form.Item 
                        name={['phone']}
                        label="Phone"
                    >
                        <Input name="phone" />
                    </Form.Item>

                </div>

                <div className="field-group">

                    <Form.Item 
                        name={['status']}
                        label="Status"
                        initialValue="active"
                    >
                        <Select key="user-status">
                            {userStatus.map( ( { text, value }, index ) => (
        
                                <Option key={ index } value={ value }>{ text }</Option>
                                
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item 
                        name={['role']}
                        label="Role"
                        initialValue="visitor"
                    >
                        <Select key="user-role" >
                            {userRoles.map( ( { text, value }, index ) => (
        
                                <Option key={ index } value={ value }>{ text }</Option>
                                
                            ))}
                        </Select>
                    </Form.Item>

                </div>
                
                <div className="field-group">

                    <Form.Item 
                        name="password"
                        label="Password"
                        rules={[
                            { min: 8, message: 'Password must be at least 8 characters' },
                            { required: true, message: 'Please input password' }
                        ]}
                    >
                        <Input type="password" name="password" />
                    </Form.Item>
                    <Form.Item />
                </div>
                <Form.Item >
                    <Button
                        loading={ loading }
                        disabled={ loading }
                        type="primary"
                        htmlType="submit"
                    >
                        Save
                    </Button>
                </Form.Item>
               

            </Form>
        </>
    );

};

export default UserCreateForm;