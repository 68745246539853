import React from 'react';
import { DrawerStandart } from 'components/service';
import { Link } from "react-router-dom";

import { UserTag } from 'components/user/helpers'; 

import './company-user-datails-drawer.scss'

const CompanyUserDatailsDrawer = ( { user } ) => {

    let full_name = `${user?.first_name} ${user?.last_name}`;


		return(
			<>
				<DrawerStandart
					title="Information" 
					buttonText={ full_name }
					className="drawer-button"
				>
					<>
                        <h3 className="text-center">User</h3>
						<div className="short-info-user">
							<p><strong>Full name:</strong><span className="first-span">{ full_name } <Link to={`/users/${ user.id }`}>[edit]</Link></span></p>
							<p><strong>Email:</strong><span className="first-span">{ user.email }</span></p>
							<p><strong>phone:</strong><span className="first-span">{ user.phone ?? 'n/a' }</span></p>
							<p><strong>status:</strong><span className="first-span"><UserTag >{ user.status }</UserTag></span></p>
							<p><strong>role:</strong><span className="first-span"><UserTag >{ user.role }</UserTag></span></p>
						</div>

						<h3 className="mt-3 text-center">Locations</h3>

					</>
				</DrawerStandart> 
			</>
		);

};

export default CompanyUserDatailsDrawer;