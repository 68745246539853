import CompanyCreateForm from './company-create-form';
import CompanyEditForm from './company-edit-form';
import CompanyAddUserForm from './company-add-user-form';

const Forms = {
    CompanyCreateForm : CompanyCreateForm,
    CompanyEditForm : CompanyEditForm,
    CompanyAddUserForm : CompanyAddUserForm
};

export default Forms;