import React from "react";
import { Skeleton } from "antd";

import { tableHelper } from "utils";
import LocationFields from "components/location/fields";
import VisitFields from "components/visit/fields";
import { VisitDatailsDrawer } from "components/visit/visit-drawer"; 


const userVisitsTableBuilder = ({
        loading,
        rowAmount = 15,
        data = [...Array(rowAmount)],
        onlyHeader
    }) => {


    const tableData = data.map( ( row = {}, index ) => {

        return {
            key: row.id ? row.id : `row_index_${index}`,
            id : {
                title : "ID",
                value : row.id,
                columnIndex:'ID',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "5%"
            },
            location_id : {
                title : "Location",
                value : <LocationFields.TitleLink location={ row.location }/>,
                columnIndex:'LOCATION_ID',
                sorter: true,
                empty: <Skeleton.Input className="table-skeleton full" active />,
                ellipsis: true,
                width: "25%"
            },
            place_id : {
                title : "Place",
                value : <>{ row.place_id ?? 'n/a' }</>,
                columnIndex:'PLACE_ID',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "15%"
            },
            covid_id : {
                title : "Covid",
                value : <VisitFields.CovidIcon covid={ row.covid } /> ,
                columnIndex:'COVID_ID',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            created_at : {
                title : "created date",
                value : row.created_at,
                columnIndex:'CREATED_AT',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            action : {
                title : "action",
                value : <VisitDatailsDrawer visit={ row } />,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            }
        };
    });

    return tableHelper.normalize( tableData, loading, onlyHeader );

}

export default userVisitsTableBuilder;