import React from "react";
import { useMutation } from "@apollo/client";
import { Form, Input, Button, Select } from 'antd';

import { companyStatus } from "components/company/helpers";

import { COMPANY_UPDATE } from "graphql/mutation/company-gql";
// import { GET_COMPANY } from "graphql/query/company-qql";
import { errorNotification, successNotification } from "components/request-result";
import { metaFields } from "utils";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};


const submitForms = ( { values, companyUpdateRequest, company } ) => {

    let { metaValues, fieldValues } = metaFields.parseForm( values );

    metaValues = metaFields.filterEmpty( metaValues, company.meta_fields );

    companyUpdateRequest({
        variables: {
            id : parseInt( company.id ),
            companyInput: {
                title: fieldValues.title,
                business_ID: fieldValues.business_ID,
                email: fieldValues.email,
                status: fieldValues.status
            },
            metaInput: [ ...metaValues ]
        }
    });

};

const CompanyEditForm = ( { company } ) => {

    const [ form ] = Form.useForm();
    const { normalize, getValue } = metaFields;
    const meta = normalize( company.meta_fields ); 

    const [companyUpdateRequest, { loading }] = useMutation( COMPANY_UPDATE,
        {
            update(cache, { data }) {

                const {
                    companyUpdate : {
                        label,
                        message,
                        // company
                    }
                } = data;

                // cache.writeQuery(  {
                //     query: GET_COMPANY,
                //     data: {
                //         user: {
                //             ...user
                //         }
                //     }
                // });

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <>
            <h3>Edit Company</h3>
            <Form
                key="edit-company"
                form={ form }
                {...layout} className="edit-company"
                onFinish ={ ( values ) => submitForms( { values, companyUpdateRequest, company } ) }
            >
                <div className="field-group">

                    <Form.Item 
                        initialValue={ company?.status }
                        name="status-field"
                        label="Status"
                    >
                        <Select key="company-status">
                            {companyStatus.map( ( { text, value }, index ) => (

                                <Option key={ index } value={ value }>{ text }</Option>
                                
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item />

                </div>

                <div className="field-group">
                    
                    <Form.Item 
                        initialValue={ company?.title }
                        name="title-field"
                        label="Title" 
                        rules={[
                            { required: true, message: 'Please input Title' }
                        ]}
                    >
                        <Input name="title" />
                    </Form.Item>

                    <Form.Item 
                        initialValue={ company?.business_ID }
                        name="business_ID-field" 
                        label="Business ID"
                        rules={[
                            { required: true, message: 'Please input Business ID' }
                        ]}
                    >
                        <Input name="business_ID" />
                    </Form.Item>

                </div>

                <div className="field-group">
                    
                    <Form.Item 
                        name="company_description-string--general"
                        label="Description" 
                        initialValue={ getValue( meta, "company_description" ) }
                    >
                        <Input.TextArea 
                            rows={3}
                            maxLength={ 200 }
                            name="company_description" />
                    </Form.Item>

                </div>

                <h3 className="mt-4">Contact info</h3>

                <div className="field-group">
                
                    <Form.Item 
                        name="first_name-string--contacts"
                        label="First name"
                        initialValue={ getValue( meta, "first_name" ) }
                        rules={[
                            { required: true, message: 'Please input First name' }
                        ]}
                    >
                        <Input name="first_name" />
                    </Form.Item>

                    <Form.Item 
                        name="last_name-string--contacts" 
                        initialValue={ getValue( meta, "last_name" ) }
                        label="Last name" 
                        rules={[
                            { required: true, message: 'Please input Last name' }
                        ]}
                    >
                        <Input name="last_name" />
                    </Form.Item>

                </div>

                <div className="field-group">
                
                    <Form.Item 
                        initialValue={ company?.email }
                        name="email-field"
                        label="Email" 
                        rules={[{ required: true, message: 'Please input Email', type: 'email' }]}
                    >
                        <Input name="email" />
                    </Form.Item>

                    <Form.Item 
                        name="company_site_url-link--contacts" 
                        initialValue={ getValue( meta, "company_site_url" ) }
                        label="Website" 
                    >
                        <Input name="company_site_url" />
                    </Form.Item>

                </div>

                <div className="field-group">
                
                    <Form.Item 
                        name="company_phone-string--contacts"
                        initialValue={ getValue( meta, "company_phone" ) }
                        label="Phone"
                        rules={[
                            { required: true, message: 'Please input Phone' }
                        ]}
                    >
                        <Input name="company_phone" />
                    </Form.Item>

                    <Form.Item 
                        name="company_fax-string--contacts"
                        initialValue={ getValue( meta, "company_fax" ) }
                        label="Fax" 
                    >
                        <Input name="company_fax" />
                    </Form.Item>

                </div>

                <h3 className="mt-4">Address info</h3>
                <div className="field-group">

                    <Form.Item 
                        name="company_country-string--address"
                        initialValue={ getValue( meta, "company_country" ) }
                        label="Country" 
                    >
                        <Input name="company_country" />
                    </Form.Item>

                    <Form.Item 
                        name="company_city-string--address"
                        initialValue={ getValue( meta, "company_city" ) }
                        label="City" 
                    >
                        <Input name="company_city" />
                    </Form.Item>
                    
                </div>

                <div className="field-group">

                    <Form.Item 
                        name="company_address-string--address" 
                        initialValue={ getValue( meta, "company_address" ) }
                        label="Address"
                        rules={[
                            { required: true, message: 'Please input Address' }
                        ]}
                    >
                        <Input name="company_address" />
                    </Form.Item>

                    <Form.Item 
                        name="company_zip-string--address" 
                        initialValue={ getValue( meta, "company_zip" ) }
                        label="ZIP" 
                    >
                        <Input name="company_zip" />
                    </Form.Item>
                    
                </div>

                <div className="form-actions mt-5">
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={ loading }
                        disabled={ loading }
                    >
                        Update
                    </Button>
                </div>
            </Form>
        </>
    );

};

export default CompanyEditForm;