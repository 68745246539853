import React from "react";
import { Col, Row } from 'antd';
import { CompanyTag, CompamyTitleTooltip } from "components/company/helpers";
import { CompanyDeleteButton } from "../company-button";

const CompanyHeaderPage = ( { company } ) => {

    return(
        <Row wrap={false} className="page-heading mb-5">
            <Col flex="auto">
                <h3 className="mb-2"><CompanyTag className="">{ company?.status }</CompanyTag> <CompamyTitleTooltip company={ company } /></h3>
                <p className="fs-13">create date: { company.created_at }</p>
            </Col>
            <Col flex="none" className="ps-2">
                <CompanyDeleteButton company={ company } />
            </Col>
        </Row>
    )
    
}

export default CompanyHeaderPage;