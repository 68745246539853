import React from "react";
import { Tabs } from 'antd';

import Forms from "../forms"
import { CompanyHeaderPage } from "../helpers";
import CompanyEmployeesTab from "./company-employees-tab";
import CompanyLocationsTab from "./company-locations-tab";


const { TabPane } = Tabs;

const CompanyTabs = ( { company } ) => {

    return(
        
        <Tabs tabPosition="left" className="single-content">
            <TabPane tab="Edit Company" key="company-tabPane-1">
                
                <CompanyHeaderPage company={ company } />

                <div className="page-content">

                    <Forms.CompanyEditForm company={ company } />

                </div>

            </TabPane>

            <TabPane tab="Employees" key="company-tabPane-3">

                <CompanyHeaderPage company={ company } />

                <CompanyEmployeesTab company={ company } />

            </TabPane>

            <TabPane tab="Locations" key="company-tabPane-4">

                <CompanyHeaderPage company={ company } />

                <CompanyLocationsTab company={ company } />

            </TabPane>

            {/* <TabPane tab="Covid Alert" disabled key="company-tabPane-3">

                <CompanyHeaderPage company={ company } />

            </TabPane> */}

    
        </Tabs>
    );
}

export default CompanyTabs;