import React from "react";
import { Skeleton } from "antd";

import { tableHelper } from "utils";
import LocationFields from "components/location/fields";
import { LocationVisitsPerMonth, LocationVisitsDangerous, LocationCountEmployees } from "components/location/helpers/location-analytics-helper";


const companyLocationsTableBuilder = ({
        loading,
        rowAmount = 15,
        data = [...Array(rowAmount)],
        onlyHeader
    }) => {


    const tableData = data.map( ( row = {}, index ) => {

        return {
            key: row.id ? row.id : `row_index_${index}`,
            id : {
                title : "ID",
                value : row.id,
                columnIndex:'ID',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "5%"
            },
            title : {
                title : "Location Name",
                value : <LocationFields.TitleLink location={ row } />,
                columnIndex:'TITLE',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                ellipsis: true,
                width: "20%"
            },
            analytics : {
                title : "Analytics",
                value : <>
                    <LocationVisitsPerMonth location={ row } />
                    <LocationVisitsDangerous location={ row } />
                    <LocationCountEmployees location={ row } />
                </>,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "15%"
            },
            type : {
                title : "Type",
                value : row.type ?? '',
                columnIndex:'TYPE',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            created_at : {
                title : "created date",
                value : row.created_at,
                columnIndex:'CREATED_AT',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            status : {
                title : "Status",
                value : <LocationFields.StatusField status={ row.status } />,
                columnIndex:'STATUS',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            action : {
                title : "action",
                value : <LocationFields.EditLink location={ row } />,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "5%"
            }
        };
    });

    return tableHelper.normalize( tableData, loading, onlyHeader );

}

export default companyLocationsTableBuilder;