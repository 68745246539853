import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Popconfirm } from 'antd';

import { COMPANY_DELETE } from "graphql/mutation/company-gql";
import { errorNotification, successNotification } from "components/request-result";

const CompanyDeleteButton = ( { company } ) => {

    let history = useHistory();

    const [visible, setVisible] = React.useState(false);

    const [companyDeleteRequest, { loading }] = useMutation( COMPANY_DELETE,
        {
            update(cache, { data }) {

                // const {
                //     companyDelete : {
                //         company
                //     }
                // } = data;

                successNotification({
                    title: 'Success',
                    description: 'You have deleted the Company'
                });
                
                // const {
                //     userDelete : {
                //         label,
                //         message
                //     }
                // } = data;

                // successNotification({
                //     title: label,
                //     description: message
                // });

                history.push( `/companies` );

            },
            onError(error){
                errorNotification(error);
            }
        }

    );

    return(
        <>
            <Popconfirm
                title={ <>Are you sure you want to delete <b>{ company?.title }</b></> }
                visible={ visible }
                onConfirm={ () => {
                    companyDeleteRequest({
                        variables: {
                            id: company.id
                        }
                    })
                }}
                okButtonProps={ { loading } }
                onCancel={ () => setVisible( false ) }
                >
                <Button 
                    key="company-delete"
                    className="mb-2"
                    danger
                    type="primary"
                    onClick={ () => setVisible( true ) }
                >
                    Delete
                </Button>
            </Popconfirm>
        </>
    );

};

export default CompanyDeleteButton;