import React, { useState } from 'react';
import { useQuery } from "@apollo/client";
import { Select } from "antd";

import { GET_USERS_AUTOCOMPLETE } from 'graphql/query/user-gql';

import { UserTag } from 'components/user/helpers';

const { Option } = Select;

const UserAutoSelectSearch = ( { company, ...props } ) => {

    const [ searhText, setSearchText ] = useState('');

    const { loading, data } = useQuery( GET_USERS_AUTOCOMPLETE, {
        variables: {
            text: searhText,
        }
    });

    const selectSearchField = data && (

        data.users.data.map( ( { id, first_name, last_name, role, company_id } ) => {

            return (
                <Option disabled={ company_id } key={ id } value={ id }>
                    { first_name } { last_name } <span className="float-right"><UserTag>{ role }</UserTag></span>
                </Option>
            )

        })

    );

    return(
        
        <Select
            className="select-autocomplete"
            dropdownClassName="select-dropdown-space-between"
            loading={ loading }
            showSearch
            filterOption={false}
            placeholder="Select a user"
            onSearch={ ( value ) => setSearchText( value ) }
            { ...props }
        >

            { selectSearchField }

        </Select>
        
    );

}

export default UserAutoSelectSearch;