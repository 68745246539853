import React, { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Form, Button, Select } from 'antd';

import { TranslateFindCodeCreate } from "../find-code-create";

import { GET_TRANSLATION_FILE_JSON } from "graphql/query/translate-qql";

import { useLanguage } from "components/use-hooks";

const { Option } = Select;

// const useGetJson = () => {

//     const { errorFileJsonQuery, loadingFileJsonQuery, dataFileJson } = useQuery( GET_TRANSLATION_FILE_JSON );


// }

const TranslateFormFileJson = ( { setJsonFile } ) => {

    const { languages } = useLanguage();

    const [ form ] = Form.useForm();

    const [loadTranslationFileJson, {  loading, data: { translationsFileJsonQuery } = {} }] = useLazyQuery( GET_TRANSLATION_FILE_JSON, {
        variables: {
            language_slug: form.getFieldValue("language_slug")
        },
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true
    } );
    
    useEffect( () => {
        if( translationsFileJsonQuery ){
            setJsonFile( translationsFileJsonQuery.jsonFile);
        }
    }, [translationsFileJsonQuery, setJsonFile]);

    
    return(
        <>
            <TranslateFindCodeCreate />
            <Form
                key="translates-show-json"
                className="d-flex"
                form={ form }
                // onFinish={ ( { language_slug } ) => {
                //     console.log( language_slug );
                    
                // }}
            >
                <Form.Item 
                    initialValue="en"
                    name="language_slug"
                    label="Language"
                >
                    <Select key="language-slug">

                        { languages && languages.map( ( { slug, label } ) => {

                            return <Option key={ slug } value={ slug }>{ label }</Option>;

                        })}

                    </Select>
                </Form.Item>
                <Button 
                    onClick={ () => {
                        loadTranslationFileJson();
                    } }
                    loading={ loading }
                    disabled={ loading }
                >
                    Show current file json
                </Button>

            </Form>
        </>
    );

};

export default TranslateFormFileJson;