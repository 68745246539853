import CompanyTitleLinkField from "./company-title-link-field";
import CompanyEditLinkField from "./company-edit-link-filed";
import CompanyStatusField from "./company-status-field";

const CompanyFields = {
    TitleLink : CompanyTitleLinkField,
    EditLink : CompanyEditLinkField,
    StatusField : CompanyStatusField
}

export default CompanyFields;