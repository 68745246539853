import React from 'react';
import { Timeline, Skeleton, Empty, Button } from 'antd';

import { useQuery } from "@apollo/client";
import { GET_VISITS_USER } from 'graphql/query/visit-qql';

import { LocationTitleLinkTooltip } from "components/location/helpers";
import { WarningOutlined } from '@ant-design/icons';

const VisitUserLastVisit = ( { visitEmail, setVisitId } ) => {

    const { data : { visits } = {}, loading } = useQuery( GET_VISITS_USER, {
        variables: {
            first: 10,
            // email: visit.email,
            where: {
                column: 'EMAIL',
                operator: "LIKE",
                value: visitEmail
            },
            orderBy: [{ 
                column: "CREATED_AT", 
                order: "DESC" 
            }],
            page: 1
        }
    } );

    return(
        <div className="visit-datails-user-history">
            <h3 className="my-4 text-center">History last 10 visits</h3>

            { loading && <Skeleton active={true} paragraph={{ rows: 10 }} /> }

            { !loading && !visits && <Empty /> }

            { !loading && 

                <Timeline>
                        
                    { visits.data.map( ( item ) => {

                            return (
                                <Timeline.Item key={ item.id } color={ item.covid_id ? "red" : "blue" } >
                                    <div className="d-flex justify-content-between align-items-center mb-2">
                                        <b>
                                            { item.covid_id ? <span className="warning-covid me-2"><WarningOutlined twoToneColor="#eb2f96" /> Covid</span> : '' }
                                            { item.created_at }
                                        </b> 
                                        <Button
                                            type="dashed"
                                            onClick={ () => setVisitId( item.id ) }
                                        >
                                                view
                                        </Button>
                                    </div>
                                    <p className="visit-location-list">
                                        <b>location:</b> 
                                        <LocationTitleLinkTooltip location={ item.location } />
                                    </p>
                                    <p><b>place:</b> { item.place_id ? '' : 'n/a' }</p>
                                    <p><b>guests:</b> { item.number_guests ? item.number_guests : 'n/a' }</p>
                                </Timeline.Item>
                            );

                        })
                    }
                
                </Timeline>
            
            }
            
        </div>
    );

};

export default VisitUserLastVisit;