import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Row } from 'antd';

import { GET_LOCATIONS } from "graphql/query/location-qql";

import { TableRender } from "utils";
import companyLocationsTableBuilder from "./company-locations-table-builder";
import CompanyLocationsTableFilter from './company-locations-table-filter';


const CompanyLocationsTab = ( { company } ) => {

    const history = useHistory();

    let match = useRouteMatch( `/companies/${ company.id }/locations/page/:currentPage` );

    const basePath = `/companies/${ company.id }/locations`;

    const defaultOrderBy = { column: "CREATED_AT", order: "DESC" },
          companyId = { column: "COMPANY_ID", operator: "EQ", value: company.id },
          currentPage = match ? parseInt( match.params.currentPage ) : 1,
          perPage = 25;

    const [ searchText, setSearchText ] = useState ( "" );
    const [ orderBy, setOrderBy ] = useState ( [ defaultOrderBy ] );
    const [ filters, setFilters ] = useState( companyId );
    const [ total, setTotal ] = useState();

    const { data : { locations = {} } = {}, loading } = useQuery( GET_LOCATIONS, {
        variables: {
            text: searchText,
            first: perPage,
            page: currentPage,
            orderBy,
            where: { ...filters }
        },
        fetchPolicy: 'cache-and-network'
    } );

    const { paginatorInfo = {} } = locations;

    useEffect( () => {

        if( ( paginatorInfo && !total ) || ( paginatorInfo.total >= 0 && paginatorInfo.total !== total ) ){
            setTotal( paginatorInfo.total );
            history.replace( basePath );
        }

    }, [ paginatorInfo, total, history, basePath ] );

    return(
        <>
            <div className="table-header p-3">
                <Row className="title-header mb-4">
                    <h3>Locations</h3>
                </Row>

                <Row className="filter-header">

                    <CompanyLocationsTableFilter currentCompany={ companyId } setFilters={ setFilters } setSearchText={ setSearchText } />

                    <span className="float-right">total: { !total ? '0' : total }</span>

                </Row>
            </div>

            <TableRender
                tableBuilder={ companyLocationsTableBuilder } 
                data={ locations?.data }
                loading={ loading }
                setOrderBy={ setOrderBy }
                defaultOrder={ defaultOrderBy }
                setFilters={ setFilters }
                pagination={{
                    pageSize: perPage,
                    total,
                    hideOnSinglePage: ( !total ) / ( total - perPage < 0 ),
                    current: currentPage,
                    onChange: ( page ) => {
                        let path = page === 1 ? `/companies/${ company.id }/locations` : `/companies/${ company.id }/locations/page/${ page }`
                        history.push( path );
                    }
                }}
            />

        </>
    );


}

export default CompanyLocationsTab;