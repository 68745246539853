import { gql } from '@apollo/client';


export const GET_VISIT = gql`
    query GetVisit(
        $id: ID,
    ) {
        visit(
            id: $id,
        ){
            id
            remote_addr
            email
            user_id
            first_name
            last_name
            phone
            location_id
            place_id
            covid_id
            number_guests
            checkout_at
            created_at
            updated_at
            user{
                id
                first_name
                last_name
                email
                phone
                status
                role
                avatar
                company_id
                company{
                    id
                    title
                    status
                    logo
                }
            }
            location{
                id
                title
                company_id
                type
                status
                qrcode
                start_at
                end_at
                company{
                    id
                    title
                    status
                    logo
                }
            }
            place{
                id
                title
                type
            }
            covid{
                id
                location_id
                email
                type
                status
                message
                moderator_id
                moderator_notice
                created_at
                updated_at
                moderator{
                    id
                    first_name
                    last_name
                    status
                    role
                }
            }
        }
    }
`;

export const GET_VISITS = gql`
    query GetVisits(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QueryVisitsOrderByOrderByClause!],
        $where: QueryVisitsWhereWhereConditions,
    ){
        visits(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data{
                id
                remote_addr
                email
                user_id
                first_name
                last_name
                phone
                location_id
                place_id
                covid_id
                number_guests
                checkout_at
                created_at
                updated_at
                user{
                    id
                    first_name
                    last_name
                    email
                    phone
                    status
                    role
                    avatar
                    company_id
                    company{
                        id
                        title
                        status
                        logo
                    }
                }
                location{
                    id
                    title
                    company_id
                    type
                    status
                    qrcode
                    start_at
                    end_at
                    company{
                        id
                        title
                        status
                        logo
                    }
                }
                place{
                    id
                    title
                    type
                }
                covid{
                    id
                    location_id
                    email
                    type
                    status
                    message
                    moderator_id
                    moderator_notice
                    created_at
                    updated_at
                    moderator{
                        id
                        first_name
                        last_name
                        status
                        role
                    }
                }
            }
        }
    }
`;

export const GET_VISITS_USER = gql`
    query GetVisitsUser(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QueryVisitsOrderByOrderByClause!],
        $where: QueryVisitsWhereWhereConditions,
    ){
        visits(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            data{
                id
                remote_addr
                email
                user_id
                first_name
                last_name
                phone
                location_id
                place_id
                covid_id
                number_guests
                checkout_at
                created_at
                updated_at
                user{
                    id
                    first_name
                    last_name
                    email
                    phone
                    status
                    role
                    avatar
                    company_id
                    company{
                        id
                        title
                        status
                        logo
                    }
                }
                location{
                    id
                    title
                    company_id
                    type
                    status
                    qrcode
                    start_at
                    end_at
                    company{
                        id
                        title
                        status
                        logo
                    }
                }
                place{
                    id
                    title
                    type
                }
                covid{
                    id
                    location_id
                    email
                    type
                    status
                    message
                    moderator_id
                    moderator_notice
                    created_at
                    updated_at
                    moderator{
                        id
                        first_name
                        last_name
                        status
                        role
                    }
                }
            }
        }
    }
`;