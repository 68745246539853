import React from 'react';
import { useQuery } from "@apollo/client";
import { Tabs, Skeleton, Empty } from 'antd';
import { VisitUser } from 'components/visit/visit-user';
import { VisitNoticeCreate, VisitNoticeUpdate } from 'components/visit/visit-notice';

import { GET_VISIT } from 'graphql/query/visit-qql';

import { WarningOutlined } from '@ant-design/icons';


const { TabPane } = Tabs;

const NoticeVisitDatailsTabs = ( { visitId } ) => {

    const { data : { visit } = {}, loading } = useQuery( GET_VISIT, {
        variables: {
            id: visitId
        }
    });

    let tabTitle = "Form Covid";

    if( visit && visit.covid_id ){

        tabTitle = <><WarningOutlined className="warning-covid me-1" twoToneColor="#eb2f96" /> Form Covid</>;

    }

    return(
        <>
            <Tabs defaultActiveKey="general-information" centered className="visit-details-drawer">
                    
                <TabPane tab="Visit Information" key="general-information">

                    { loading && <Skeleton active={true} paragraph={{ rows: 10 }} /> }

                    { !loading && !visit && <Empty /> }

                    { !loading && <VisitUser visit={ visit } /> }

                </TabPane>

                <TabPane tab={ tabTitle } key="form-covid">
                    
                    <h3 className="text-center mt-2">Form Covid</h3>

                    { visit && !visit.covid_id && <VisitNoticeCreate visit={ visit } /> }
                    
                    { visit && visit.covid_id &&
                        <VisitNoticeUpdate visit={ visit } />
                    }

                </TabPane>
                
            </Tabs>
        </>
    );

};

export default NoticeVisitDatailsTabs;