import React from "react";
import { Select } from "antd";

import { CompanyTag } from "components/company/helpers";
import useQueryCompaniesSelect from "./hooks/use-query-company-select";
import { GET_COMPANIES_AUTOCOMPLETE } from "graphql/query/company-qql";

const { Option } = Select;

const CompanyAutoSelectSearch = ( props ) => {
  
    const {
        loadingQuery,
        dataQuery,
        onSearch
    } = useQueryCompaniesSelect( GET_COMPANIES_AUTOCOMPLETE );
  
    const selectSearchField = dataQuery && (

        dataQuery.map( ( { id, title, status } ) => {
            return (
                <Option key={ id } value={ id }>
                    { title } <span className="float-right"><CompanyTag >{ status }</CompanyTag></span>
                </Option>
            )
        })

    );
  
    return (
        <Select
            onSelect={ props.onSelect }
            className="select-input-space-between select-auto-search"
            dropdownClassName="select-dropdown-space-between"
            loading={ loadingQuery }
            onSearch={ ( value ) => onSearch( value ) }
            showSearch
            onClear={ props.onClear }
            allowClear={ !!props.onClear }
            placeholder="Select a Company"
            { ...props }
            filterOption={false}
        >
            { selectSearchField }
        </Select>
    );

};
    
export default CompanyAutoSelectSearch;
    