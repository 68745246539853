import React, { useState, useEffect } from "react";
import { Input } from 'antd';

import { gqlBuilderWhere } from "utils";
import { UserStatusFilter, UserRoleFilter } from "../filters";
import CompanyAutoSelectSearch from "components/company/forms/company-autoselect-search";


const { Search } = Input;

const UsersTableFilter = ( { setSearchText, setFilters } ) => {

    const [ filterList, setFilterList ] = useState({
        status : {},
        role: {},
        companyId : {},
    });

    useEffect( () => {
        setFilters( gqlBuilderWhere( filterList ) );
    }, [filterList, setFilters] );

    return (
        <>
            <Search 
                className="search-header"
                placeholder="any search"
                onSearch={ ( value ) => setSearchText( value ) }  
            />

            <UserRoleFilter 
                filterList={ filterList } setFilterList={ setFilterList }
            />

            <CompanyAutoSelectSearch 
                onSelect={ ( value ) => {

                    setFilterList( { 
                        ...filterList,
                        companyId : {
                            column: "COMPANY_ID",
                            operator: "IN",
                            value: [value]
                        }
                    })

                }} 
                onClear={ () => {
                    setFilterList( { 
                        ...filterList,
                        companyId : {},
                    })
                }}
            />

            <UserStatusFilter 
                filterList={ filterList } setFilterList={ setFilterList }
            />


        </>
    );

};

export default UsersTableFilter;