import React, { useState, useEffect } from "react";
import { Input} from 'antd';

import { VisitsFilterCovidTrue, VisitsFilterCreateDateBetween, VisitsFilterGuestAuth } from "components/visit/filters";
import { gqlBuilderWhere } from "utils";


const { Search } = Input;

const LocationVisitsFilters = ( { setFilters, setSearchText, location } ) => {

    const [ filterList, setFilterList ] = useState({
        userId : {},
        locationId : {
            column: "LOCATION_ID",
            operator: "EQ",
            value: location.id
        },
        createDate : {},
        covidId : {} 
    });

    useEffect( () => {
        setFilters( gqlBuilderWhere( filterList ) );
    }, [filterList, setFilters] );

    return (
        <>
            <Search 
                className="search-header w-100"
                placeholder="any search"
                onSearch={ ( value ) => setSearchText( value ) }  
            />
            
            <VisitsFilterGuestAuth setFilterList={ setFilterList } filterList={ filterList } />

            <VisitsFilterCovidTrue setFilterList={ setFilterList } filterList={ filterList } />

            <VisitsFilterCreateDateBetween setFilterList={ setFilterList } filterList={ filterList } />

        </>
    );

};

export default LocationVisitsFilters