import React from "react";
import { Select } from "antd";

import { LocationTag } from "components/location/helpers";
import useQueryLocationsSelect from "./hook/use-query-location-select";
import { GET_LOCATIONS_AUTOCOMPLETE } from "graphql/query/location-qql";

const { Option } = Select;

const LocationAutoSelectSearch = ( props ) => {
  
    const {
        loadingQuery,
        dataQuery,
        onSearch
    } = useQueryLocationsSelect( GET_LOCATIONS_AUTOCOMPLETE );
  
    const selectSearchField = dataQuery && (

        dataQuery.map( ( { id, title, status } ) => {
            return (
                <Option key={ id } value={ id }>
                    { title } <span className="float-right"><LocationTag >{ status }</LocationTag></span>
                </Option>
            )
        })

    );
  
    return (
        <Select
            onSelect={ props.onSelect }
            className="select-input-space-between select-auto-search"
            dropdownClassName="select-dropdown-space-between"
            loading={ loadingQuery }
            onSearch={ ( value ) => onSearch( value ) }
            showSearch
            onClear={ props.onClear }
            allowClear={ !!props.onClear }
            placeholder="Select a location"
            { ...props }
            filterOption={false}
        >
            { selectSearchField }
        </Select>
    );

};
    
export default LocationAutoSelectSearch;