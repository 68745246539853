import React from "react";
import { Row } from 'antd';

import { CompanyTable } from "components/company";
import { useActiveMenuItem } from "components/use-hooks";
 
import './company.scss';


const CompanyPage = () => {

    useActiveMenuItem( ["companies-all"], ["companies"] );

    return(
        <Row justify="center" className="page page-companies fullscreen">
            
            <CompanyTable />
            
        </Row>
    );
};

export default CompanyPage;