import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from 'antd';

import { TRANSLATION_FIND_CREATE } from "graphql/mutation/translate-gql";
import { errorNotification, successNotification } from "components/request-result";

const TranslateFindCodeCreate = () => {

    const [translationFindInCodeAndCreateRequest, { loading }] = useMutation( TRANSLATION_FIND_CREATE,
        {
            update( cache, { data } ) {

                const {
                    translationFindInCodeAndCreate : {
                        label,
                        message,
                    }
                } = data;
                
                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <>
            <Button 
                loading={ loading }
                disabled={ loading }
                key="location-delete"
                onClick={ () => {
                    translationFindInCodeAndCreateRequest()
                }}
            >
                Find & Create Translations
            </Button>
        </>
    );

};

export default TranslateFindCodeCreate;