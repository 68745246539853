import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from 'antd';

import { VISITS_EXPORT } from "graphql/mutation/visit-gql";
import { errorNotification, successNotification } from "components/request-result";

import { DownloadOutlined } from '@ant-design/icons';


const VisitsExport = ( { searchText, filters } ) => {

    const [visitsExport, { loading }] = useMutation( VISITS_EXPORT,
        {
            update(cache, { data }) {

                const {
                    visitsExport : {
                        label,
                        message,
                        file
                    }
                } = data;

                const link = document.createElement('a');

                link.href = file;

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <Button
            className="ant-btn ant-btn-primary"
            type="primary"
            loading={ loading }
            disabled={ loading || !filters }
            onClick={ () => {
                visitsExport({
                    variables: {
                        text: searchText,
                        where: { ...filters }
                    }
                }).catch( ( error ) => {
                    errorNotification( error );
                });
            } }
        >
            <DownloadOutlined /> Export XLS
        </Button>
    );

};

export default VisitsExport;