import React from "react";
import { UserTag } from "../helpers";

const UserRoleField = ( { user } ) => {

    return(
        <UserTag >{ user.role }</UserTag>
    );

};

export default UserRoleField;