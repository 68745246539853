import UserInfoField from "./user-info-field";
import UserRoleField from "./user-role-field";
import UserStatusField from "./user-status-field";
import UserCompanyField from "./user-company-field";
import UserActionEditField from "./user-action-edit-field";


const UserFields = {
    Info : UserInfoField,
    Role : UserRoleField,
    Status: UserStatusField,
    Company : UserCompanyField,
    ActionEdit: UserActionEditField
}

export default UserFields;