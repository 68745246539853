import { useState } from "react";
import { useQuery } from "@apollo/client";


const useQueryCompaniesSelect = ( query = true ) => {

    const [ searhText, setSearchText ] = useState('');

    const { loading, error, data } = useQuery( query, {
        variables: {
            text: searhText,
        }
    });

    const onSearch = ( value ) => { setSearchText( value ) }

    return {
        loadingQuery: loading,
        errorQuery: error,
        dataQuery: data?.companies.data,
        onSearch
    };
}

export default useQueryCompaniesSelect;
