import React from "react";
import { Select } from 'antd';

import { useLanguage } from "components/use-hooks";

const { Option } = Select;

const TranslatesTableFilter = ( { setFilters, filters } ) => {

    const { languages } = useLanguage();

    return (
        <>
            <span>
                <b>Type</b>
                <Select
                    key="select-type-translate"
                    className="select-type-translate ms-2"
                    placeholder="Select Type Translation"
                    onChange={ ( value ) => {
                        setFilters( { 
                            ...filters,
                            type : value,
                        })
                    }}
                    defaultValue="core"
                >
                    <Option value="core">core</Option>
                    <Option value="adm">adm</Option>
                    <Option value="crm">crm</Option>
                    <Option value="app">app</Option>
                </Select>
            </span>

            <span>
                <b>Group</b>
                <Select
                    key="group-translate"
                    className="select-group-translate ms-2"
                    placeholder="Select Group Translation"
                    defaultValue="all"
                >
                    <Option value="all">All</Option>
                </Select>
            </span>

            <span>
                <b>From</b>
                <Select 
                    key="parent-translate"
                    className="select-parent-translate ms-2"
                    placeholder="Select Parent Translation"
                    defaultValue="en"
                    onChange={ ( value ) => {
                        setFilters( { 
                            ...filters,
                            language_slug : value,
                        })
                    }}
                >

                    { languages && languages.map( ( { slug, label } ) => {

                        return <Option key={ slug } value={ slug }>{ label }</Option>;

                    })}

                </Select>
            </span>

            <span>
                <b>To</b>
                <Select
                    key="child-translate"
                    className="select-child-translate ms-2"
                    placeholder="Select Child Translation"
                    onChange={ ( value ) => {
                        setFilters( { 
                            ...filters,
                            child_slug : value,
                        })
                    }}
                    defaultValue="en"
                >
                    { languages && languages.map( ( { slug, label } ) => {

                        return <Option key={ slug } value={ slug }>{ label }</Option>;

                    })}

                </Select>
            </span>

        </>
    );

};

export default TranslatesTableFilter;