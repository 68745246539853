import React from "react";
import { Row } from 'antd';

import { VisitsTable } from "components/visit";
import { useActiveMenuItem } from "components/use-hooks";

import './visits.scss'


const VisitsPage = () => {

    useActiveMenuItem( ["visits-all"], ["visits"] );

    return(
        <Row justify="center" className="page page-visits fullscreen">
            <VisitsTable />
        </Row>
    );
};

export default VisitsPage;