import React, { useState, useEffect } from "react";

import LocationAutoSelectSearch from "components/location/forms/location-autoselect-search";
import { VisitsFilterCovidTrue, VisitsFilterCreateDateBetween } from "components/visit/filters";
import { gqlBuilderWhere } from "utils";


const UserVisitsTableFilter = ( { currentUser, setFilters } ) => {

    const [ filterList, setFilterList ] = useState({
        userId : currentUser,
        locationId : {},
        createDate : {},
        covidId : {} 
    });

    useEffect( () => {
        setFilters( gqlBuilderWhere( filterList ) );
    }, [filterList, setFilters] );


    return (
        <>
            <LocationAutoSelectSearch 
                onSelect={ (value) => {
                    setFilterList( { 
                        ...filterList,
                        locationId : {
                            column: "LOCATION_ID",
                            operator: "EQ",
                            value: value
                        },
                    })
                }} 
                onClear={ () => {
                    setFilterList( { 
                        ...filterList,
                        locationId : {},
                    })
                }}
            />

            <VisitsFilterCovidTrue setFilterList={ setFilterList } filterList={ filterList } />

            <VisitsFilterCreateDateBetween setFilterList={ setFilterList } filterList={ filterList } />

        </>
    );

};

export default UserVisitsTableFilter;