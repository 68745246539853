import React, { useState } from 'react';
import { Button, Drawer } from 'antd';
import { cloneChildren } from 'utils';

const DrawerStandart = ( { width = '500', title = '', buttonText = 'click', className = '', buttonType = 'text', ...props } ) => {

    const [ visible, setVisible ] = useState(false);
    
    return(
        <>
            <Button 
                onClick={ () => { setVisible(true) }}
                className={ className }
                type={ buttonType }
            >
                { buttonText }
            </Button>
            <Drawer 
                width={ width }
                title={ title }
                placement="right" 
                onClose={ () => { setVisible(false) }} 
                visible={visible}
                >
                    {    
                        cloneChildren( props )
                    }
            </Drawer>
        </>
    );
};

export default DrawerStandart;