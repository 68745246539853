import { gql } from '@apollo/client';

export const COMPANY_CREATE = gql`
    mutation CompanyCreate(
        $input: CompanyUpdateInput
    ) {
        companyCreate(
            input: $input
        ){
            label
            message
            company{
                id
                title
                status
                logo
                email
                business_ID
                created_at
                updated_at
                delete_at
                meta_fields{
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
            }
        }
    }
`;

export const COMPANY_UPDATE = gql`
    mutation CompanyUpdate(
        $id : ID!
        $companyInput : CompanyUpdateInput
        $metaInput : [CompanyMetaInput]
    ){
        companyUpdate(
            id : $id
            input : $companyInput
        ){
            label
            message
        }
        companyMetaSet(
            company_id : $id
            input : $metaInput
        ){
            label
            message
            company{
                id
                title
                status
                logo
                business_ID
                email
                meta_fields{
                    id
                    meta_group
                    meta_key
                    meta_type
                    meta_value
                }
                created_at
                updated_at
                delete_at
            }
        }
    }
`;

export const COMPANY_DELETE = gql`
    mutation CompanyDelete(
        $id: ID!
    ) {
        companyDelete(
            id: $id
        ){
            id
            title
        }
    }
`;

export const COMPANY_USER_DELETE = gql`
    mutation CompanyUserDelete(
        $id: ID!
        $user_id: ID!
    ) {
        companyUserDelete(
            id: $id
            user_id: $user_id
        ){
            label
            message
            company{
                id
                title
                status
                logo
                business_ID
                email
                meta_fields{
                    id
                    meta_group
                    meta_key
                    meta_type
                    meta_value
                }
                created_at
                updated_at
                delete_at
            }
        }
    }
`;