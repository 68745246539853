import React from "react";
import { Skeleton } from "antd";

import { tableHelper } from "utils";
import CompanyFields from "../fields";
import { CompanyCountEmployees, CompanyCountLocations } from "../helpers/company-analytics-helper"; 


const companiesTableBuilder = ({
        loading,
        rowAmount = 15,
        data = [...Array(rowAmount)],
        onlyHeader
    }) => {


    const tableData = data.map( ( row = {}, index ) => {

        return {
            key: row.id ? row.id : `row_index_${index}`,
            id : {
                title : "ID",
                value : row.id,
                columnIndex:'ID',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "5%"
            },
            title : {
                title : "Company Name",
                value : <CompanyFields.TitleLink company={ row } />,
                columnIndex:'TITLE',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                ellipsis: true,
                width: "25%"
            },
            analytics : {
                title : "Analytics",
                value : <>
                    <CompanyCountEmployees company={ row } />
                    <CompanyCountLocations company={ row } />
                </>,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "15%"
            },
            email : {
                title : "email",
                value : row.email,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            business_ID : {
                title : "business ID",
                value : row.business_ID,
                columnIndex:'BUSINESS_ID',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            status : {
                title : "status",
                value : <CompanyFields.StatusField status={ row.status } />,
                columnIndex:'STATUS',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            created_at : {
                title : "created date",
                value : row.created_at,
                columnIndex:'CREATED_AT',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            action : {
                title : "action",
                value : <CompanyFields.EditLink company={ row } />,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "5%"
            }
        };
    });

    return tableHelper.normalize( tableData, loading, onlyHeader );

}

export default companiesTableBuilder;