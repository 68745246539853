import React from "react";
import { Skeleton } from "antd";

import { tableHelper } from "utils";
import LocationFields from "../fields";
import { LocationVisitsPerMonth, LocationVisitsDangerous, LocationCountEmployees } from "../helpers/location-analytics-helper"; 

import CompanyTitleLinkField from "components/company/fields/company-title-link-field";

const locationsTableBuilder = ({
        loading,
        rowAmount = 15,
        data = [...Array(rowAmount)],
        onlyHeader
    }) => {


    const tableData = data.map( ( row = {}, index ) => {

        return {
            key: row.id ? row.id : `row_index_${index}`,
            id : {
                title : "ID",
                value : row.id,
                columnIndex:'ID',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "5%"
            },
            title : {
                title : "Location Name",
                value : <LocationFields.TitleLink location={ row } />,
                columnIndex:'TITLE',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                ellipsis: true,
                width: "25%"
            },
            analytics : {
                title : "Analytics",
                value : <>
                    <LocationVisitsPerMonth location={ row } />
                    <LocationVisitsDangerous location={ row } />
                    <LocationCountEmployees location={ row } />
                </>,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "15%"
            },
            type : {
                title : "Type",
                value : row.type ?? '',
                columnIndex:'TYPE',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            company : {
                title : "Company",
                value : <CompanyTitleLinkField company={ row.company } />,
                columnIndex:'COMPANY_ID',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "25%"
            },
            created_at : {
                title : "created date",
                value : row.created_at,
                columnIndex:'CREATED_AT',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            status : {
                title : "Status",
                value : <LocationFields.StatusField status={ row.status } />,
                columnIndex:'STATUS',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "5%"
            },
            action : {
                title : "action",
                value : <LocationFields.EditLink location={ row } />,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "5%"
            }
        };
    });

    return tableHelper.normalize( tableData, loading, onlyHeader );

}

export default locationsTableBuilder;