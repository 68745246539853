import React from "react";
import { Checkbox } from 'antd';

import { WarningOutlined  } from '@ant-design/icons';


const VisitsFilterCovidTrue = ( { setFilterList, filterList } ) => {

    return (
        <div className="visits-filter-covid-true">
            <Checkbox 
                onChange={ ( e ) => {

                    if( e.target.checked ){

                        setFilterList( { 
                            ...filterList,
                            covidId : {
                                column: "COVID_ID",
                                operator: "IS_NOT_NULL"
                            },
                        })

                    } else {

                        setFilterList( { 
                            ...filterList,
                            covidId : {},
                        })

                    }
                    
                }}
            >
                <WarningOutlined className="warning-covid" twoToneColor="#eb2f96" /> Covid
            </Checkbox>

        </div>
    );

};

export default VisitsFilterCovidTrue