import React from 'react';
import { Link } from "react-router-dom";

import { useQuery } from "@apollo/client";
import { GET_NOTICE_FORMS_NEW } from 'graphql/query/notice-form-gql';


const NoticeFormsNewCount = () => {

    const { data : { noticeForms } = {} } = useQuery( GET_NOTICE_FORMS_NEW, {
        variables: {
            first: 1,
            page: 1,
            where: { 
                column: "STATUS",
                operator: "EQ",
                value: 'new' 
            }
        },
        fetchPolicy: "network-only"
    } );

    return( 
        <>
            <Link className={ noticeForms?.paginatorInfo.total > 0 ? 'amount-notice-forms-not-empty' : '' } to="/notice-forms">Covid Forms { !noticeForms ? '0' : `(${ noticeForms.paginatorInfo.total })` }</Link>  
        </>
    );
};

export default NoticeFormsNewCount;