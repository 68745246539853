import React, { useState, useEffect } from "react";
import { Input } from 'antd';

import LocationAutoSelectSearch from "components/location/forms/location-autoselect-search";
import { VisitsFilterGuestAuth, VisitsFilterCreateDateBetween, VisitsFilterCovidTrue } from "../filters";
import { gqlBuilderWhere } from "utils";


const { Search } = Input;

const VisitsTableFilter = ( { setFilters, setSearchText } ) => {

    const [ filterList, setFilterList ] = useState({
        userId : {},
        locationId : {},
        createDate : {},
        covidId : {} 
    });

    useEffect( () => {
        setFilters( gqlBuilderWhere( filterList ) );
    }, [filterList, setFilters] );


    return (
        <>
            <Search 
                className="search-header w-100"
                placeholder="any search"
                onSearch={ ( value ) => setSearchText( value ) }  
            />

            <VisitsFilterGuestAuth setFilterList={ setFilterList } filterList={ filterList } />

            <VisitsFilterCovidTrue setFilterList={ setFilterList } filterList={ filterList } />

            <LocationAutoSelectSearch 
                onSelect={ (value) => {
                    setFilterList( { 
                        ...filterList,
                        locationId : {
                            column: "LOCATION_ID",
                            operator: "EQ",
                            value: value
                        },
                    })
                }} 
                onClear={ () => {
                    setFilterList( { 
                        ...filterList,
                        locationId : {},
                    })
                }}
            />

            <VisitsFilterCreateDateBetween setFilterList={ setFilterList } filterList={ filterList } />

        </>
    );

};

export default VisitsTableFilter;