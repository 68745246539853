import React from "react";
import { Col, Row } from 'antd';
import { UserTag } from "components/user/helpers";
import Forms from "components/user/forms";

const UserHeaderPage = ( { user } ) => {

    return(
        <Row wrap={false} className="page-heading mb-4">
            <Col flex="auto">
                <h3 className="mb-2"><UserTag>{ user?.status }</UserTag> { user?.first_name } { user?.last_name}</h3>
                <span className="float-left me-2"><UserTag>{ user?.role }</UserTag></span>
                <p className="fs-13">last visit: { user?.last_visit_at ? user.last_visit_at: 'n/a' }</p>
            </Col>
            <Col flex="none">
                <Forms.UserDeleteForm user={ user } />
            </Col>
        </Row>
    )
    
}

export {
    UserHeaderPage
}