import React from "react";
import { Link } from "react-router-dom";


const Page404 = () => {

    return(
        <div className="page-404">
            <h1>404</h1>
            <p>Sorry, that page doesn't exist</p>
            <Link to="/" className="link-text-bold">Go on main</Link>
        </div>
    );
}

export default Page404;