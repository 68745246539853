import React from "react";
import { Tag } from 'antd';

const noticeFormTagColor = ( value ) => {

    switch( value ){

        case 'new':
            return 'error';

        case 'approved':
            return 'orange';

        case 'declined':
            return 'default';

        default:
            return '';
    }

}

const NoticeFormTag = (props) => {

    const { children } = props;

    return(
        <Tag color={ noticeFormTagColor( children ) }>{ children?.toUpperCase() }</Tag>
    )
}

export {
    NoticeFormTag
}