import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch, useHistory } from "react-router-dom";
import { Table, Input, Row } from 'antd';

import { GET_TRANSLATIONS } from "graphql/query/translate-qql"; 

import { TranslatesTableFilter } from "../table-filters";
import { translatesTableBuilder } from "../helpers";
import { tableHelper } from "utils";


const { Search } = Input;

const TranslateTable = ({
        data,
        loading,
        pagination,
        setOrderBy,
        defaultOrder,
        filters
}) => {

    const { dataSource, columns } = translatesTableBuilder({
        data : !loading && !pagination.total ? [""] : data,
        loading,
        rowAmount : tableHelper.tableRowsCount(pagination),
        onlyHeader: !loading && !data.length,
        filters
    });

    return(
        <Table 
            dataSource={ dataSource }
            columns={ columns }  
            pagination={ pagination }
            onChange={ ( pagination, filters, { column, order } ) => {

                if( column && order ){
                    
                    setOrderBy([
                        { 
                            column: column.columnIndex, 
                            order: order === "ascend" ? "ASC" : "DESC"  
                        }
                    ])

                } else { 
                    setOrderBy( [ defaultOrder ] ) 
                }

            }}
        />
    )

}

const TranslateTableAll = () => {

    const history = useHistory();

    let match = useRouteMatch( '/translations/page/:currentPage' );

    const defaultOrderBy = { column: "CREATED_AT", order: "DESC" },
          defaultParam = { type: 'core', language_slug: 'en', child_slug: 'en' },
          currentPage = match ? parseInt( match.params.currentPage ) : 1,
          perPage = 30;

    const [ searchText, setSearchText ] = useState ( "" ),
          [ orderBy, setOrderBy ] = useState ( [ defaultOrderBy ] ),
          [ filters, setFilters ] = useState( defaultParam ),
          [ total, setTotal ] = useState();
    
    const { data : { translations = {} } = {}, loading } = useQuery( GET_TRANSLATIONS, {
        variables: {
            text: searchText,
            first: perPage,
            page: currentPage,
            orderBy,
            ...filters,
        }
    } );

    const { paginatorInfo = {} } = translations;

    useEffect( () => {

        if( (paginatorInfo && !total) ||  (paginatorInfo.total >= 0 && paginatorInfo.total !== total) ){
            setTotal( paginatorInfo.total );
            history.replace( `/translations` );
        }

    }, [ paginatorInfo, total, history ] );

    return(
        <>

            <div className="table-header p-3">

                <Row className="title-header mb-4">
                    <h1>Translations</h1>
                </Row>

                <Row className="filter-header mb-4">
                    
                    <Search 
                        className="search-header w-100"
                        placeholder="any search"
                        onSearch={ ( value ) => setSearchText( value ) }  
                    />

                    <TranslatesTableFilter setFilters={ setFilters } filters={ filters } />

                    <span className="float-right">total: { total }</span>

                </Row>
                <TranslateTable
                    data={ translations.data }
                    loading={ loading }
                    setOrderBy={ setOrderBy }
                    defaultOrder={ defaultOrderBy }
                    pagination={{
                        pageSize: perPage,
                        total,
                        hideOnSinglePage: (!total) || (total - perPage < 0),
                        current: currentPage,
                        onChange: ( page ) => {
                            let path = page === 1 ? `/translations` : `/translations/page/${ page }`
                            history.push( path );
                        },
                        showSizeChanger: false
                    }}
                    filters={ filters }
                />
            </div>
            
        </>
    );
};

export default TranslateTableAll;