import React, { useState, useEffect } from "react";
import { Input } from 'antd';

import { gqlBuilderWhere } from "utils";
import { CompaniesStatusFilter } from "../filters";


const { Search } = Input;

const CompaniesTableFilter = ( { setFilters, setSearchText } ) => {

    const [ filterList, setFilterList ] = useState({
        status : {},
    });

    useEffect( () => {
        setFilters( gqlBuilderWhere( filterList ) );
    }, [filterList, setFilters] );


    return (
        <>
            <Search 
                className="search-header w-100"
                placeholder="any search"
                onSearch={ ( value ) => setSearchText( value ) }  
            />

            <CompaniesStatusFilter setFilterList={ setFilterList } filterList={ filterList } />
           

        </>
    );

};

export default CompaniesTableFilter;