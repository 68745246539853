import React from "react";
import { useMutation } from "@apollo/client";
import { Form, Input, Button, Select, Image, Col, Row } from 'antd';

import { locationStatus, locationType } from "components/location/helpers";
import { CompanyBadge, CompamyTitleLinkTooltip } from "components/company/helpers";

import { LOCATION_UPDATE } from "graphql/mutation/location-gql"; 
import { errorNotification, successNotification } from "components/request-result";
import { metaFields } from "utils";

const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const submitForms = ( { values, locationUpdateRequest, location } ) => {

    let { metaValues, fieldValues } = metaFields.parseForm( values );

    metaValues = metaFields.filterEmpty( metaValues, location.meta_fields );

    locationUpdateRequest({
        variables: {
            id : parseInt( location.id ),
            locationInput: {
                title: fieldValues.title,
                business_ID: fieldValues.business_ID,
                email: fieldValues.email,
                status: fieldValues.status
            },
            metaInput: [ ...metaValues ]
        }
    });

};

const LocationEditForm = ( { location } ) => {

    const [ form ] = Form.useForm();
    const { normalize, getValue } = metaFields;
    const meta = normalize( location.meta_fields ); 

    const [locationUpdateRequest, { loading }] = useMutation( LOCATION_UPDATE,
        {
            update(cache, { data }) {

                const {
                    locationUpdate : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification( error );
            }
        }
    );

    return(
        <>
            <Row wrap={false} className="mb-4">
                <Col flex="auto" className="me-3">
                    <h3 className="mt-4 fw-500">
                        <p className="d-flex">
                            <span className="me-2">Company:</span> 
                            <CompanyBadge>{ location.company?.status }</CompanyBadge> 
                            <CompamyTitleLinkTooltip company={ location.company } />
                        </p>
                    </h3>
                </Col>
                <Col className="qr-code-locattion">
                    <span><b>QR-Code</b></span>
                    <Image
                        className=""
                        width={105}
                        src={ location.qrcode }
                    />
                </Col>
            </Row>

            <h3>Edit Location</h3>
            <Form
                key="edit-location"
                form={ form }
                {...layout} className="edit-location"
                onFinish ={ ( values ) => submitForms( { values, locationUpdateRequest, location } ) }
            >
                <div className="field-group">
                    <Form.Item 
                        initialValue={ location?.title }
                        name="title-field" 
                        label="Title" 
                        rules={[
                            { required: true, message: 'Please input Title' }
                        ]}
                    >
                        <Input name="title" />
                    </Form.Item>
                </div>

                <div className="field-group">

                    <Form.Item 
                        initialValue={ location?.status }
                        name="status-field"
                        label="Status"
                    >
                        <Select key="location-status">
                            {locationStatus.map( ( { text, value }, index ) => (

                                <Option key={ index } value={ value }>{ text }</Option>
                                
                            ))}
                        </Select>

                    </Form.Item>

                    <Form.Item 
                        initialValue={ location.type }
                        name="type-field"
                        label="Type"
                    >
                        <Select key="location-type" disabled>
                            { locationType.map( ( { text, value }, index ) => (
        
                                <Option disabled={ value !== location.type } key={ index } value={ value }>{ text }</Option>
                                
                            )) }
                        </Select>
                    </Form.Item>

                </div>

                <div className="field-group">
                    
                    <Form.Item 
                        initialValue={ getValue( meta, "location_description" ) }
                        name="location_description-string--general"
                        label="Description" 
                    >
                        <Input.TextArea 
                            rows={3}
                            maxLength={ 200 }
                            name="location_description" />
                    </Form.Item>

                </div>

                <h3 className="mt-4">Contact info</h3>
                
                <div className="field-group">
                
                    <Form.Item 
                        initialValue={ getValue( meta, "location_email" ) }
                        name="location_email-string--contacts" 
                        label="Email" 
                        // rules={[{ required: true, message: 'Please input Email', type: 'email' }]}
                    >
                        <Input name="email" />
                    </Form.Item>

                    <Form.Item 
                        initialValue={ getValue( meta, "location_site_url" ) }
                        name="location_site_url-link--contacts" 
                        label="Website"
                    >
                        <Input name="location_site_url" />
                    </Form.Item>

                </div>

                <div className="field-group">
                
                    <Form.Item 
                        initialValue={ getValue( meta, "location_phone" ) }
                        name="location_phone-string--contacts"
                        label="Phone"
                        // rules={[
                        //     { required: true, message: 'Please input Phone' }
                        // ]}
                    >
                        <Input name="location_phone" />
                    </Form.Item>

                    <Form.Item 
                        initialValue={ getValue( meta, "location_mobile_phone" ) }
                        name="location_mobile_phone-string--contacts"
                        label="Mobile Phone" 
                    >
                        <Input name="location_mobile_phone" />
                    </Form.Item>

                </div>

                <h3 className="mt-4">Address info</h3>
                <div className="field-group">

                    <Form.Item 
                        initialValue={ getValue( meta, "location_city" ) }
                        name="location_city-string--address"
                        label="City" 
                        rules={[{ required: true, message: 'Please input City' }]}
                    >
                        <Input name="location_city" />
                    </Form.Item>

                    <Form.Item 
                        initialValue={ getValue( meta, "location_zip" ) }
                        name="location_zip-string--address"
                        label="ZIP" 
                    >
                        <Input name="location_zip" />
                    </Form.Item>
                    
                    
                    
                </div>

                <div className="field-group">

                    <Form.Item
                        initialValue={ getValue( meta, "location_street" ) } 
                        name="location_street-string--address"
                        label="Street" 
                        rules={[{ required: true, message: 'Please input Street'}]}
                    >
                        <Input name="location_street" />
                    </Form.Item>


                    <Form.Item 
                        initialValue={ getValue( meta, "location_number" ) }
                        name="location_number-string--address"
                        label="Number"
                    >
                        <Input name="location_number" />
                    </Form.Item>

                   
                    
                </div>

                <div className="form-actions mt-5">
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={ loading }
                        disabled={ loading }
                    >
                        Update
                    </Button>
                </div>

            </Form>
        </>
    );

};

export default LocationEditForm;