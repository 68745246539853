import { gql } from '@apollo/client';


export const GET_LOCATIONS = gql`
    query GetLocations(
        $text: String,
        $company_id: ID,
        $type: LocationType,
        $status: LocationStatus,
        $first: Int,
        $page: Int,
        $orderBy: [QueryLocationsOrderByOrderByClause!],
        $where: QueryLocationsWhereWhereConditions,
    ){
        locations(
            text: $text,
            company_id: $company_id,
            type: $type,
            status: $status,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data{
                id
                title
                company_id
                type
                status
                logo
                preview_banner
                latitude
                longitude
                slug
                qrcode
                start_at
                end_at
                created_at
                updated_at
                visitsPerMonth{
                    month
                    count
                }
                visitsDangerous
                meta_fields{
                    id
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
                company{
                    id
                    title
                    status
                    count_employees
                }
            }
            
        }
    }
`;

export const GET_LOCATIONS_AUTOCOMPLETE = gql`
    query GetLocationsAutoComplete(
        $text: String
    ){
        locations(
            text: $text
        ){
            data{
                id
                title
                company_id
                type
                status
                company{
                    id
                    title
                    status
                }
            }
            
        }
    }
`;

export const GET_LOCATION = gql`
    query GetLocation(
        $id: ID,
    ) {
        location(
            id: $id,
        ){
            id
            title
            company_id
            type
            status
            logo
            preview_banner
            latitude
            longitude
            slug
            qrcode
            start_at
            end_at
            created_at
            updated_at
            visitsPerMonth{
                month
                count
            }
            visitsDangerous
            meta_fields{
                id
                meta_key
                meta_type
                meta_value
                meta_group
            }
            company{
                id
                title
                status
                count_employees
            }
        }
    }
`;