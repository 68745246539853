import React from 'react';
import { Breadcrumb } from 'antd';
import './app-breadcrumb.scss';

const AppBreadcrumb = () => {
    return( 
        <Breadcrumb className="page-subheading">
            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
            {/* <Breadcrumb.Item>List</Breadcrumb.Item>
            <Breadcrumb.Item>App</Breadcrumb.Item> */}
        </Breadcrumb>
     );
};

export default AppBreadcrumb;