import React from 'react';
import { useMutation } from "@apollo/client";
import { Form, Input, Button, Select } from 'antd';

import { noticeStatus } from 'components/notice-forms/helpers';

import { NOTICE_FORM_CREATE } from 'graphql/mutation/notice-form-gql';

import { errorNotification, successNotification } from "components/request-result";


const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const VisitNoticeCreate = ( { visit } ) => {

    const [ form ] = Form.useForm();

    const [noticeFormCreate, { loading }] = useMutation( NOTICE_FORM_CREATE,
        {
            update(cache, { data }) {

                const {
                    noticeFormCreate : {
                        label,
                        message,
                        noticeForm
                    }
                } = data;

                cache.modify({
                    id: cache.identify( visit ),
                    fields: {
                        covid_id(){
                            return noticeForm.id;
                        },
                        covid(){
                            return { __ref: `${ noticeForm.__typename }:${ noticeForm.id }`};
                        }
                    },
                });

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }

    );

    return(

        <Form
            key="visit-notice-create"
            
            form={ form }
            onFinish={(values) => {
                noticeFormCreate({
                    variables: {
                        input: {
                            visit_id: visit.id,
                            ...values
                        }
                    }
                }).catch((error) => {
                    errorNotification(error);
                });
            }}
            {...layout} className="create-company">

            <p>
                <strong>Visit ID: <span className="first-span">{ visit.id }</span>
                </strong>
            </p>

            <div className="field-group">

                <Form.Item 
                    initialValue={ visit.email }
                    name={ 'email' } 
                    label="User Email"
                >
                    <Input disabled name="email" />
                </Form.Item>

            </div>

            <div className="field-group">

                <Form.Item 
                    name={'message'} 
                    label="User message" 
                    rules={[
                        { message: 'Please input message' }
                    ]}
                >
                    <Input.TextArea 
                        rows={3}
                        maxLength={ 2500 }
                        name="message" 
                    />
                </Form.Item>

            </div>

            <div className="field-group">

                <Form.Item 
                    name={'moderator_notice'} 
                    label="Moderator Notice" 
                    rules={[
                        { message: 'Please input Title' }
                    ]}
                >
                    <Input.TextArea 
                            rows={3}
                            maxLength={ 2500 }
                            name="moderator_notice" />
                </Form.Item>

            </div>

            <div className="field-group">

                <Form.Item 
                    name={['status']}
                    label="Status"
                    initialValue="new"
                    rules={[
                        { required: true, message: 'Please select status' }
                    ]}
                >
                    <Select key="notice-status">
                        { noticeStatus.map( ( { text, value }, index ) => (
    
                            <Option disabled={ value === 'declined' } key={ index } value={ value }>{ text }</Option>
                            
                        ))}
                    </Select>
                </Form.Item>

            </div>

            <div className="form-actions mt-5">
                <Button
                    loading={ loading }
                    disabled={ loading }
                    type="primary"
                    htmlType="submit"
                >
                    Create
                </Button>
            </div>
            

        </Form>
        
    );

}

export default VisitNoticeCreate;