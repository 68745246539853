import React, { useState } from "react";
import { useRouteMatch, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Row } from 'antd';

import { GET_VISITS } from "graphql/query/visit-qql";

import VisitsExport from "../visits-export";
import VisitsTableFilter from "./visits-table-filter";
import VisitsTableRender from "./visits-table-render";

import './visits-table.scss';


const VisitsTable = () => {

    const history = useHistory();

    let match = useRouteMatch( '/visits/page/:currentPage' );

    const defaultOrderBy = { column: "CREATED_AT", order: "DESC" },
          currentPage = match ? parseInt( match.params.currentPage ) : 1,
          perPage = 50;

    const [ searchText, setSearchText ] = useState ( "" );
    const [ orderBy, setOrderBy ] = useState ( [ defaultOrderBy ] );
    const [ filters, setFilters ] = useState();

    const { data : { visits } = {}, loading } = useQuery( GET_VISITS, {
        variables: {
            text: searchText,
            first: perPage,
            page: currentPage,
            orderBy,
            where: { ...filters }
        },
        fetchPolicy: "network-only",
        nextFetchPolicy: "cache-first"
    } );
    
    const { paginatorInfo } = visits ?? {};

    return(
        <>
            <div className="table-header p-3">

                <Row className="title-header mb-4">

                    <h1>Visits</h1>

                    <VisitsExport searchText={ searchText } filters={ filters } />

                </Row>

                <Row className="filter-header">
                    
                    <VisitsTableFilter setFilters={ setFilters } setSearchText={ setSearchText } />

                    <span className="float-right">total: { !visits ? '0' : visits.paginatorInfo.total }</span>

                </Row>

            </div>

            <VisitsTableRender 
                data={ visits?.data }
                loading={ loading }
                setOrderBy={ setOrderBy }
                defaultOrder={ defaultOrderBy }
                setFilters={ setFilters }
                pagination={{
                    pageSize: paginatorInfo?.perPage,
                    total: paginatorInfo?.total,
                    hideOnSinglePage: paginatorInfo?.total / paginatorInfo?.perPage > 1,
                    current: currentPage,
                    onChange: ( page ) => {
                        let path = page === 1 ? `/visits` : `/visits/page/${ page }`
                        history.push( path );
                    }
                }}
            />
            
        </>
    );

};


export default VisitsTable;