import React from "react";
import { Table } from 'antd';

import visitsTableBuilder from "./visits-table-builder";
import { tableHelper } from "utils";
// import { gqlWhere } from "utils";


const VisitsTableRender = ({
    data,
    loading,
    pagination,
    setOrderBy,
    defaultOrder
}) => {

    const { dataSource, columns } = visitsTableBuilder({
        data : !loading && !pagination.total ? [""] : data,
        loading,
        rowAmount : tableHelper.tableRowsCount( pagination ),
        onlyHeader: !loading && !data.length
    });

    return(
        <Table 
            dataSource={ dataSource }
            columns={ columns }  
            pagination={ pagination }
            onChange={ ( pagination, filters, { column, order } ) => {

                // setFilters( gqlWhere( filters ) );
               
                if( column && order ){
                    
                    setOrderBy([
                        { 
                            column: column.columnIndex, 
                            order: order === "ascend" ? "ASC" : "DESC"  
                        }
                    ])

                } else { setOrderBy( [ defaultOrder ] ) }

            }}
            // scroll={ { x:true } }
        />
    )

}

export default VisitsTableRender;