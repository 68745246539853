import React, { useState } from "react";
import { Row, Table } from 'antd';

import { useLanguage } from "components/use-hooks";
import { PageWrapDefault } from "components/wraps";
import TranslateFormGenerateJson from "./translate-form-generate-json";
import TranslateFormFileJson from "./translate-form-file-json";


const translateSyncData = ( syncJsonFile ) => {

    let counter = 1;

    return Object.entries( syncJsonFile ).map( ( [key, value] ) => {
        return {
            number: counter++,
            key: key,
            translate: value,
        };
    });

};

const TranslateGenerateJson = () => {

    const { languages } = useLanguage();

    const [jsonFile, setJsonFile] = useState( {} );

    const columnsTranslateSync = [
        {
            title: 'N', dataIndex: 'number', key: 'number', width: '5%', align: 'center'
        },
        {
            title: 'Key', dataIndex: 'key', key: 'key', width: '35%'
        },
        {
            title: 'Translate', dataIndex: 'translate', key: 'translate', width: '60%'
        },
    ];

    return(

        <PageWrapDefault className="page-translates-sync single">
            <div className="table-header p-3">
                <Row className="title-header mb-4">

                    <h1>Generation of translations to json file</h1>

                </Row>

                <Row className="d-flex justify-content-between">

                    <div>
                        
                        <TranslateFormGenerateJson setJsonFile={ setJsonFile } />

                        <div className="short-info-language">

                            { languages && languages.map( ( language ) => {

                                return (
                                    <p key={ language.id }>
                                        <strong>{ language.label }</strong>
                                        <span className="first-span">last date generate { language?.sync_date?.core?.date }{ !language.sync_date && `n/a` }</span>
                                    </p>
                                );

                            })}

                        </div>
                       
                    </div>

                    <TranslateFormFileJson setJsonFile={ setJsonFile } />

                </Row>

            </div>

            { jsonFile && 
                <Table 
                    className="table-sync-json-file"
                    columns={ columnsTranslateSync } 
                    dataSource={ translateSyncData( jsonFile ) } 
                    pagination={false}
                />
            }

        </PageWrapDefault>
    );

};

export default TranslateGenerateJson;