import React from "react";
import { Tag, Badge } from 'antd';

const companyTagColor = ( value ) => {

    switch( value ){

        case 'draft':
            return 'orange';

        case 'active':
            return 'success';

        case 'block':
            return 'error';

        case 'closed':
            return 'default';

        default:
            return '';
    }

}

const CompanyTag = (props) => {

    const { children } = props;

    return(
        <Tag color={ companyTagColor( children ) }>{ children?.toUpperCase() }</Tag>
    )
}

const CompanyBadge = (props) => {

    const { children } = props;

    return(
        <Badge status={ companyTagColor( children ) }  />
    )
}

export {
    CompanyTag,
    CompanyBadge
}