import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Form, Input } from "antd";

import { USER_CHANGE_PASSWORD } from "graphql/mutation/user-gql";
import { errorNotification, successNotification } from "components/request-result";

const UserChangePasswordForm = ( { user } ) => {

    const [ form ] = Form.useForm();

    const [changePasswordRequest, { loading }] = useMutation( USER_CHANGE_PASSWORD,
        {
            update(cache, { data }) {

                const {
                    userChangePassword : {
                        label,
                        message
                    }
                } = data;


                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 12 },
    };

    return(
        <>
            <h3>Change Password</h3>
            <Form
                key="change-password-user"
                className="change-password-form mt-4"
                {...formItemLayout}
                form={ form }
                onFinish={
                    (values) => {

                        changePasswordRequest({
                            variables : {
                                input: {
                                    user_id: user.id,
                                    new_password: values["new-password"],
                                    new_password_confirmation: values["confirm-password"]
                                }
                            }
                        }).catch((error) => {
                            errorNotification(error);
                        });
                    }
                }
            >
                <Form.Item
                    name="new-password"
                    label="New password"
                    rules={[
                        { min: 8, message: 'Password must be at least 8 characters' },
                        { required: true, message: 'Please input new Password' }
                    ]}
                >
                    <Input.Password type="password" name="new-password"/>
                </Form.Item>

                <Form.Item
                    name="confirm-password"
                    label="Confirm password"
                    rules={[
                        { required: true, message: 'Please confirm new password' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('new-password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                        })
                    ]}
                >
                    
                    <Input.Password type="password" name="confirm-password"/>
                </Form.Item>
                <div className="form-actions">
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={ loading }
                    >
                        Update
                    </Button>
                </div>
            </Form>

        </>
    )

}

export default UserChangePasswordForm;