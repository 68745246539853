import React from "react";
import { LocationTag } from "../helpers";

const LocationStatusField = ( { status } ) => {

    return(
        <LocationTag >{ status }</LocationTag>
    );

};

export default LocationStatusField;