import React from "react";
import { Tabs } from 'antd';

import Forms from "components/user/forms";
import { UserHeaderPage } from "components/user/helpers";
import UserVisitsTable from "../user-visits";

const { TabPane } = Tabs;

const UserEditTabs = ( { user } ) => {

    
    return(
        
        <Tabs tabPosition="left" className="single-content">

            <TabPane tab="Edit Profile" key="user-tabPane-1">
                
                <UserHeaderPage user={ user } />

                <div className="page-content">

                    <Forms.UserEditForm user={ user } />

                </div>

            </TabPane>

            <TabPane tab="Change Password" key="user-tabPane-2">
                
                <UserHeaderPage user={ user } />

                <Forms.UserChangePassword user={ user } /> 
            
            </TabPane>

            <TabPane tab="Visits History" key="user-tabPane-4">

                <UserHeaderPage user={ user } />

                <UserVisitsTable user={ user } />

            </TabPane>

            {/* <TabPane tab="Company" disabled key="user-tabPane-3">

                <UserHeaderPage user={ user } />

            </TabPane>

             */}
            
            {/* <TabPane tab="Delete User" key="user-tabPane-5">

                <UserHeaderPage user={ user } />

                <Forms.UserDeleteForm user={ user } />

            </TabPane> */}

        </Tabs>
            
    );
}

export default UserEditTabs;