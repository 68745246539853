import React from 'react';
import { Descriptions, Typography } from 'antd';

import './system-info.scss';

const { Paragraph } = Typography;

const SystemInfo = ( { generalWidgets, typeKey } ) => {


    // const copyToClipBoard = async copyMe => {

    //     try {

    //         await navigator.clipboard.writeText( copyMe );

    //     } catch (err) {}
    // };

    const widgetData = generalWidgets && !!generalWidgets[typeKey] ? generalWidgets[typeKey] : [];

    if( widgetData.length < 1 ){

        return <p className="text-center">no data</p>;
        
    }

    return (
        <>
            { widgetData.map( ( { label, info }, index ) => (
                
                <Descriptions key={`system-info-widget-${index}`} size="default">
                    <Descriptions.Item label={ label }>

                        { typeKey === 'site_api' &&  <Paragraph copyable>{ info }</Paragraph> }

                        { typeKey !== 'site_api' && <Paragraph>{ info }</Paragraph>}

                    </Descriptions.Item>
                </Descriptions>
                
            ))}
        </>
    );

}

export default SystemInfo;