import React, { useState, useEffect } from "react";
import { Input } from 'antd';

import { LocationsStatusFilter, LocationsTypeFilter } from "components/location/filters"; 
import { gqlBuilderWhere } from "utils";


const { Search } = Input;

const CompanyLocationsTableFilter = ( { currentCompany, setFilters, setSearchText } ) => {

    const [ filterList, setFilterList ] = useState({
        status : {},
        type: {},
        companyId : currentCompany
    });

    useEffect( () => {
        setFilters( gqlBuilderWhere( filterList ) );
    }, [filterList, setFilters] );


    return (
        <>
            <Search 
                className="search-header w-100"
                placeholder="any search"
                onSearch={ ( value ) => setSearchText( value ) }  
            />

            <LocationsTypeFilter setFilterList={ setFilterList } filterList={ filterList } />
           
            <LocationsStatusFilter setFilterList={ setFilterList } filterList={ filterList } />
        </>
    );

};

export default CompanyLocationsTableFilter;