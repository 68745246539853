import React from "react";
import { Checkbox, Dropdown, Button, } from 'antd';

import { DownOutlined, CheckSquareOutlined, BorderOutlined } from '@ant-design/icons';


const locationStatus = [
    { label: 'draft', value: 'draft' },
    { label: 'active', value: 'active' },
    { label: 'block', value: 'block' },
    { label: 'close', value: 'close' }
];

const LocationsStatusFilter = ( { filterList, setFilterList} ) => {

    const LocationsStatusCheckbox = () => {

        return(
            <div className="dropdown-filter-group">
                <Checkbox.Group 
                    options={ locationStatus } 
                    value={ filterList.status.value } 
                    onChange={ ( checkedValues ) => {
            
                        if( checkedValues.length > 0 ){
            
                            setFilterList( { 
                                ...filterList,
                                status : {
                                    column: "STATUS",
                                    operator: "IN",
                                    value: checkedValues
                                }
                            })
            
                        } else {
            
                            setFilterList( { 
                                ...filterList,
                                status : {},
                            })

                        }
            
                    }}  
                />
                <Button 
                    className="mt-2"
                    danger type="link"
                    onClick={ () => {

                        setFilterList( { 
                            ...filterList,
                            status : {},
                        })

                    }}
                >
                    Reset
                </Button>
            </div>
        );

    };

    return(

        <Dropdown 
            overlay={ <LocationsStatusCheckbox /> } 
            placement="bottomCenter"
            arrow
        >
            <Button>
                { filterList.status.value ? <CheckSquareOutlined /> : <BorderOutlined /> }
                Status 
                <DownOutlined />
            </Button>
        </Dropdown>
        
    );
    
};

export default LocationsStatusFilter;