import { gql } from '@apollo/client';

export const GET_LANGUAGES = gql`
    query getLanguages(
        $status: Boolean
    ){
        languages(
            status: $status,
        ){
            id
            label
            slug
            status
            sync_date
        }
    }
`;
