import React, { useState } from "react";
import { useRouteMatch, useHistory, Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Row } from 'antd';

import { GET_COMPANIES } from "graphql/query/company-qql";

import CompaniesTableFilter from "./companies-table-filter";
import CompaniesTableRender from "./companies-table-render";


const CompanyTable = () => {

    const history = useHistory();

    let match = useRouteMatch( '/companies/page/:currentPage' );

    const perPage = 25;
    const defaultOrderBy = { column: "CREATED_AT", order: "DESC" };
    const currentPage = match ? parseInt( match.params.currentPage ) : 1;

    const [ searchText, setSearchText ] = useState ( "" ),
          [ orderBy, setOrderBy ] = useState ( [ defaultOrderBy ] ),
          [ filters, setFilters ] = useState();

    const { data : { companies } = {}, loading } = useQuery( GET_COMPANIES, {
        variables: {
            text: searchText,
            first: perPage,
            page: currentPage,
            orderBy,
            where: { ...filters }
        },
        fetchPolicy: 'cache-and-network'
    } );

    const { paginatorInfo } = companies ?? {};

    return(
        <>
            <div className="table-header p-3">

                <Row className="title-header mb-4">
                    <h1>Companies</h1>

                    <Link
                        className="ant-btn ant-btn-primary"
                        type="primary"
                        to={ '/companies/create' }>
                        New Company
                    </Link>

                </Row>

                <Row className="filter-header">

                    <CompaniesTableFilter setFilters={ setFilters } setSearchText={ setSearchText } />

                    <span className="float-right">total: { !companies ? '0' : companies.paginatorInfo.total } </span>
                </Row>
            </div>


            <CompaniesTableRender 
                data={ companies?.data }
                loading={ loading }
                setOrderBy={ setOrderBy }
                defaultOrder={ defaultOrderBy }
                setFilters={ setFilters }
                pagination={{
                    pageSize: paginatorInfo?.perPage,
                    total: paginatorInfo?.total,
                    hideOnSinglePage: paginatorInfo?.total / paginatorInfo?.perPage > 1,
                    current: currentPage,
                    onChange: ( page ) => {
                        let path = page === 1 ? `/companies` : `/companies/page/${ page }`
                        history.push( path );
                    }
                }}
            />
            
        </>
    );


}

export default CompanyTable;