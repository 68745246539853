import React from "react";
import { useQuery } from "@apollo/client";
import { Skeleton, Table,  Empty, Row } from 'antd';

import { GET_LANGUAGES } from "graphql/query/language-qql";


const columns = [
    {
        title: 'ID', dataIndex: 'id', align: 'center', width: '5%'
    },
    {
        title: 'Label', dataIndex: 'label', align: 'center'
    },
    {
        title: 'Slug', dataIndex: 'slug', align: 'center'
    },
    {
        title: 'status', dataIndex: 'status', align: 'center'
    },
    {
        title: 'action', dataIndex: 'action', align: 'center'
    }
];

const LanguagesTable = () => {

    const { data : { languages } = {}, loading } = useQuery( GET_LANGUAGES, {
        fetchPolicy: 'cache-and-network'
    } );

    const languageData = ( languages ) => {

        if( !languages ){
            return [];
        }
    
        return languages.map( language => {
            
            return {
                key: language.id,
                id: language.id,
                label: language.label,
                slug: language.slug,
                status: language.status ? 'active': 'not active',
                action: <>edit</>
            };
    
        });
    
    };

    return(
        <>
            <div className="table-header p-3">
                <Row className="title-header mb-4">
                    <h1>Languages</h1>

                    {/* <Button
                        type="primary">
                        New Language
                    </Button> */}

                </Row>

                <Row className="filter-header">
                    
                    <span className="float-right">total: { !languages ? '0' : languages.length } </span> 

                </Row>
            </div>

            <Table 
                className="table-languages"
                tableLayout='fixed'
                locale={{
                    emptyText: loading ? <Skeleton active={true} paragraph={{ rows: 4 }} /> : <Empty />
                }}
                dataSource={ languageData( languages ) }
                columns={columns}
                size="small"
                pagination={ false }
            />
        </>
    );
};

export default LanguagesTable;