import React from 'react';
import { Empty } from 'antd';

import { DrawerStandart } from 'components/service';
import NoticeVisitDatailsTabs from '../notice-visit-tabs';


const NoticeVisitDatailsDrawer = ( { visit } ) => {

    return(

        <DrawerStandart
            title="Information" 
            buttonText="visit"
            className="drawer-button"
            buttonType="dashed"
        >
            <>
                { !visit && <Empty /> }
                { visit && <NoticeVisitDatailsTabs visitId={ visit.id } /> }
            </>
            
        </DrawerStandart> 

    );

};

export default NoticeVisitDatailsDrawer;

