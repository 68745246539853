import React from "react";
import { Link } from "react-router-dom";
import { LocationTitleTooltip, LocationBadge } from "components/location/helpers";

const LocationTitleLinkField = ( { location } ) => {

    if( !location ){
        return null;
    }

    return(
        <p className="d-flex">
            <LocationBadge>{ location.status }</LocationBadge>
            <Link className="ellipsis" to={`/locations/${ location.id }`}>
                <LocationTitleTooltip location={ location } />
            </Link>
        </p>
    );

};

export default LocationTitleLinkField;