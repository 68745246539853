import React from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Form, Input } from "antd";

import { FORGOT_PASSWORD } from "graphql/mutation/user-gql";

import { errorNotification } from "components/request-result";
import { useVarParam } from "components/use-hooks";
// import { FloatLabel } from "components/service";


const ForgotPasswordForm = ({ formItemLayout }) => {

    const guestParams = useVarParam("guest");

    const [ form ] = Form.useForm();

    const [ forgotRequest, { loading }] = useMutation( FORGOT_PASSWORD,
        {
            update() {

                guestParams.set({
                    sendForgotPasswordEmail: true,
                });
            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <div className="form-holder forgot-password">
            { guestParams.get("sendForgotPasswordEmail") ?
                <div className="forgot-password-message">
                    <h1>
                        Passwort zurücksetzen E-Mail versendet
                    </h1>
                    <p>Wir haben Ihnen auf die angegebene E-Mail, sofern ein Account mit dieser E-Mail existiert, Anweisungen zum Festlegen Ihres Passworts zugesandt. Diese sollten sie in Kürze erhalten.</p>
                    <p>
                        Wenn Sie keine E-Mail erhalten stellen Sie bitte sicher, dass Sie die Adresse verwenden, mit der Sie sich registriert haben und überprüfen Sie bitte auch Ihren Spam-Ordner.
                    </p>
                    <p>
                        <Link to="/" className="link-text-bold">Zur Hauptseite</Link>
                    </p>
                </div>:
                <>
                    <h1>
                        Zahl der Gäste inklusive dir selbst angeben
                    </h1>
                    <Form
                        className="forgot-password-form"
                        form={ form }
                        onFinish={(values) => {
                            forgotRequest({
                                variables : {
                                    input: { email: values.email }
                                }
                            }).catch((error) => {
                                errorNotification(error);
                            });
                        }}
                    >
                        <Form.Item
                            {...formItemLayout}
                            name="email"
                            rules={[
                                {
                                    type: 'email',
                                    message: 'The input is not valid E-mail',
                                },
                                {
                                    required: true,
                                    message: 'Please input your Email'
                                }
                            ]}
                        >
                            
                            <Input name="email" placeholder="email"/>
                        </Form.Item>
                        <Form.Item>
                            <div className="form-actions">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={ loading }
                                >
                                    ABSENDEN
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                    <div className="row bordered-top">
                        <p>Haben Sie sich Ihr Passwort schon gemerkt?</p>
                        <Link to="/" className="link-text-bold">Jetzt einloggen</Link>
                    </div>
                </>
            }
        </div>
    );
}


export default ForgotPasswordForm;