import { gql } from '@apollo/client';

export const META_DELETE = gql`
    mutation MetaDelete(
        $meta_model: MetaModel!
        $meta_model_id: ID!
        $meta_key: String
    ) {
        metaDelete(
            meta_model: $meta_model
            meta_model_id: $meta_model_id
            meta_key: $meta_key
        ){
            label
            message
        }
    }
`;