import React from "react";
import { Table } from 'antd';

import companyUsersTableBuilder from "./company-users-table-builder";
import { tableHelper } from "utils";


const CompanyUsersTableRender = ({
    data,
    loading,
    pagination,
    setOrderBy,
    defaultOrder,
    company,
    variables
}) => {

    const { dataSource, columns } = companyUsersTableBuilder({
        data : !loading && !pagination.total ? [""] : data,
        loading,
        rowAmount : tableHelper.tableRowsCount( pagination ),
        onlyHeader: !loading && !data.length,
        company: company, 
        variables: variables
    });

    return(
        <Table 
            dataSource={ dataSource }
            columns={ columns }  
            pagination={ pagination }
            onChange={ ( pagination, filters, { column, order } ) => {
               
                if( column && order ){
                    
                    setOrderBy([
                        { 
                            column: column.columnIndex, 
                            order: order === "ascend" ? "ASC" : "DESC"  
                        }
                    ])

                } else { setOrderBy( [ defaultOrder ] ) }

            }}
        />
    )

}

export default CompanyUsersTableRender;