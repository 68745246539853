import React from "react";
import { Checkbox, Dropdown, Button, } from 'antd';

import { DownOutlined, CheckSquareOutlined, BorderOutlined } from '@ant-design/icons';


const locationType = [
    { label: 'restaurant', value: 'restaurant' },
    { label: 'hotel', value: 'hotel' },
    { label: 'event', value: 'event' }
];

const LocationsTypeFilter = ( { filterList, setFilterList} ) => {

    const LocationsTypeCheckbox = () => {

        return(
            <div className="dropdown-filter-group">
                <Checkbox.Group 
                    options={ locationType } 
                    value={ filterList.type.value } 
                    onChange={ ( checkedValues ) => {
            
                        if( checkedValues.length > 0 ){
            
                            setFilterList( { 
                                ...filterList,
                                type : {
                                    column: "TYPE",
                                    operator: "IN",
                                    value: checkedValues
                                }
                            })
            
                        } else {
            
                            setFilterList( { 
                                ...filterList,
                                type : {},
                            })

                        }
            
                    }}  
                />
                <Button 
                    className="mt-2"
                    danger type="link"
                    onClick={ () => {

                        setFilterList( { 
                            ...filterList,
                            type : {},
                        })

                    }}
                >
                    Reset
                </Button>
            </div>
        );

    };

    return(

        <Dropdown 
            overlay={ <LocationsTypeCheckbox /> } 
            placement="bottomCenter"
            arrow
        >
            <Button>
                { filterList.type.value ? <CheckSquareOutlined /> : <BorderOutlined /> }
                Type 
                <DownOutlined />
            </Button>
        </Dropdown>
        
    );
    
};

export default LocationsTypeFilter;