import React from "react";
import { Row } from 'antd';

import { LocationsTable } from "components/location"; 
import { useActiveMenuItem } from "components/use-hooks";

import './location.scss';

const LocationsPage = () => {

    useActiveMenuItem( ["locations-all"], ["locations"] );

    return(
        <Row justify="center" className="page page-locations fullscreen">

            <LocationsTable />
            
        </Row>
    );
};

export default LocationsPage;