import { useQuery } from "@apollo/client";

import { GET_LANGUAGES } from "graphql/query/language-qql";


const useLanguage = ( withStatus = false ) => {

    const { data : { languages } = {}, loading, error } = useQuery( GET_LANGUAGES );

    if( withStatus ) return { languages, loading, error };

    if( languages ) return { languages } ;

    return false;
}

export default useLanguage;