import React from "react";
import { useQuery } from "@apollo/client";
import { Skeleton, Empty, PageHeader } from 'antd';

import { GET_COMPANY } from "graphql/query/company-qql";

import { PageWrapDefault } from "components/wraps";
import { CompanyTabs } from "components/company";

import { useActiveMenuItem } from "components/use-hooks";

const CompanyEditPage = ({ match, history }) => {

    useActiveMenuItem( ["companies-all"], ["companies"] );

    const companyID = match ? parseInt( match.params.id ) : null;

    const { data : { company } = {}, loading } = useQuery( GET_COMPANY, {
        variables: {
            id: companyID
        }
    });

    return(
        <PageWrapDefault className="page-company single">

            <PageHeader
                className="site-page-header"
                onBack={ () => history.push( `/companies` ) }
                title="Edit Company"
            />
            
            { loading && <Skeleton active={true} paragraph={{ rows: 30 }} /> }

            { !loading && !company && <Empty /> }

            { !loading && company && 
            
                <CompanyTabs company={ company } /> 
                
            }

        </PageWrapDefault>
    );
};

export default CompanyEditPage;