import { Link } from "react-router-dom";
import { Tooltip } from 'antd';

const locationStatus = [
    { text: 'draft', value: 'draft' },
    { text: 'active', value: 'active' },
    { text: 'block', value: 'block' },
    { text: 'close', value: 'close' }
];

const locationType = [
    { text: 'restaurant', value: 'restaurant' },
    { text: 'hotel', value: 'hotel' },
    { text: 'event', value: 'event' }
];

const LocationTitleTooltip = ( { location } ) => {

    return(
        <Tooltip className="ellipsis" placement="topLeft" title={ location.title ?? location.id }>{ location.title ?? location.id }</Tooltip>
    );

}

const LocationTitleLinkTooltip = ( { location } ) => {

    return(
        <Link className="ellipsis" to={`/locations/${ location.id }`}>
            <LocationTitleTooltip location={ location } />
        </Link>
    );

}

export {
    locationStatus,
    locationType,
    LocationTitleTooltip,
    LocationTitleLinkTooltip
}