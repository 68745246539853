import React from "react";
import { WarningOutlined } from '@ant-design/icons';


const VisitCovidIconField = ( { covid } ) => {

    if( !covid ){
        return null;
    }

    let covidTrue = '';

    if( covid !== null ){

        covidTrue = <WarningOutlined 
            title={ covid.status === 'new' ? 'new' : 'approved' } 
            className={ covid.status === 'new' ? 'warning-covid' : 'notice-approved' } 
            twoToneColor="#eb2f96" />;

    } 

    return( <>{covidTrue}</> );

};

export default VisitCovidIconField;