import React, { useState, useEffect } from "react";
import { Input } from 'antd';

import { gqlBuilderWhere } from "utils";
import { UserStatusFilter, UserRoleFilter } from "components/user/filters";

const { Search } = Input;

const CompanyUsersTableFilter = ( { currentCompany, setSearchText, setFilters } ) => {

    const [ filterList, setFilterList ] = useState({
        status : {},
        role: {},
        companyId : currentCompany,
        locationId : {},
    });

    useEffect( () => {
        setFilters( gqlBuilderWhere( filterList ) );
    }, [filterList, setFilters] );

    return (
        <>
            <Search 
                className="search-header"
                placeholder="any search"
                onSearch={ ( value ) => setSearchText( value ) }  
            />

            <UserRoleFilter 
                filterList={ filterList } setFilterList={ setFilterList }
            />

            
            <UserStatusFilter 
                filterList={ filterList } setFilterList={ setFilterList }
            />

        </>
    );

};

export default CompanyUsersTableFilter;