import { HomeAppPage } from "./home";
import { LoginPage, ForgotPasswordPage } from "./authorization";
import { UsersPage, UserEditPage, UserCreatePage } from "./users";
import { CompanyPage, CompanyCreatePage, CompanyEditPage } from "./company";
import { LocationsPage, LocationCreatePage, LocationEditPage } from "./locations";
import { TranslatesGenerateJsonPage, TranslatesPage } from "./translates";
import { LanguagesPage } from "./languages";
import { VisitsPage } from "./visits"; 
import { NoticeAlarmPage } from "./notice-forms";
import Page404 from "./404/404-page";

import "./pages.scss";


export const Pages = {
    HomeAppPage,
    UsersPage,
    UserEditPage,
    UserCreatePage,
    CompanyPage,
    CompanyCreatePage,
    CompanyEditPage,
    LocationsPage,
    LocationCreatePage,
    LocationEditPage,
    VisitsPage,
    NoticeAlarmPage,
    TranslatesGenerateJsonPage,
    TranslatesPage,
    LanguagesPage,
    Page404
};

export const GuestPages = {
    LoginPage,
    ForgotPasswordPage,
}