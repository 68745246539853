import React from "react";

const UserInfoField = ( { userInfo } ) => {

    return(
        <>
            <p>{ userInfo.first_name } { userInfo.last_name ?? '' }</p>
            <p><b>{ userInfo.email }</b></p>
        </>
    );

};

export default UserInfoField;