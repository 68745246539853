import React from "react";


const cloneChildren = ( { children, ...props } ) => {


    if(!children){
        return null;
    }
    
    if(Array.isArray(children)){

        return children.map(
            ( child, index ) => {

                if (typeof child.type === "function") {
                    return React.cloneElement(
                        child,
                        {
                            key: index,
                            ...props
                        }
                    )
                }

                return React.cloneElement(child, {key: index});
            }
        );

    }

    return typeof children === "string" ? children : React.cloneElement( children, props);
}


export default cloneChildren;