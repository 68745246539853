import React from "react";
import { Link } from "react-router-dom";

const UserActionEditField = ( { userId } ) => {

    return(
        <Link to={ `/users/${ userId }` }>edit</Link>
    );

};

export default UserActionEditField;