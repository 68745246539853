import React from "react";
import { useQuery } from "@apollo/client";

import { GET_LOCATION } from "graphql/query/location-qql";

import { Skeleton, Empty, PageHeader } from 'antd';
import { useActiveMenuItem } from "components/use-hooks";
import { PageWrapDefault } from "components/wraps";
import { LocationTabs } from "components/location";

const LocationEditPage = ( { match, history } ) => {

    useActiveMenuItem( ["locations-all"], ["locations"] );
    
    const locationID = match ? parseInt( match.params.id ) : null;

    const { data : { location } = {}, loading } = useQuery( GET_LOCATION, {
        variables: {
            id: locationID
        }
    });

    return(
        <PageWrapDefault className="page-locations-edit single">
            
            <PageHeader
                className="site-page-header"
                onBack={ () => history.push( `/locations` ) }
                title="Edit Location"
            />

            { loading && <Skeleton active={true} paragraph={{ rows: 30 }} /> }

            { !loading && !location && <Empty /> }

            { !loading && location && 
            
                <>
                    <LocationTabs location={ location } /> 
                </>

            }

        </PageWrapDefault>
    );


};

export default LocationEditPage;