import React from "react";
import { useHistory } from "react-router-dom";
import { PageHeader } from 'antd';

import Forms from "components/user/forms";
import { PageWrapDefault } from "components/wraps";
import { useActiveMenuItem } from "components/use-hooks";

const UserCreatePage = () => {
    
    let history = useHistory();
    
    useActiveMenuItem( ["user-add-new"], ["users"] );
   
    return(
        <PageWrapDefault className="page-user-create single">
            
            <PageHeader
                className="site-page-header"
                onBack={ () => history.push( `/users` ) }
                title="Add new User"
            />
            
            <div className="single-content">
                <Forms.UserCreateForm user={ {} } />
            </div>

        </PageWrapDefault>
    );


};


export default UserCreatePage;