import { Tooltip } from 'antd';
import { WarningOutlined, BarChartOutlined, TeamOutlined  } from '@ant-design/icons';


const LocationVisitsPerMonth = ( { location } ) => {

    if( !location ){
        return '';
    }

    const fullText = ( visitsPerMonth ) => {

        return `Visits for ${visitsPerMonth[0].month}: ${visitsPerMonth[0].count}`;

    }

    return (
        <>
            <Tooltip className="analytics visits-per-month me-2" title={ fullText( location?.visitsPerMonth ) }>
                <BarChartOutlined className="me-2" /><span>{ location?.visitsPerMonth && location.visitsPerMonth[0].count }</span>
            </Tooltip>
        </>
    );

};

const LocationVisitsDangerous = ( { location } ) => {

    if( !location ){
        return '';
    }

    const fullText = ( visitsDangerous ) => {

        return `Corona incident reported: ${visitsDangerous}`;

    }

    return (
        <>
            <Tooltip className="analytics visits-per-month me-2" title={ fullText( location?.visitsDangerous ) }>
                <WarningOutlined className="me-2 warning-covid" /><span>{ location?.visitsDangerous && location.visitsDangerous }</span>
            </Tooltip>
        </>
    );

};

const LocationCountEmployees = ( { location } ) => {

    if( !location ){
        return '';
    }

    const fullText = ( countEmployees ) => {

        return `Employees owner: ${countEmployees}`;

    }

    return (
        <>
            <Tooltip className="analytics location-count-employees me-2" title={ fullText( location?.company.count_employees ) }>
                <TeamOutlined className="me-2" /><span>{ location.company.count_employees }</span>
            </Tooltip>
        </>
    );

};

export{
    LocationVisitsPerMonth,
    LocationVisitsDangerous,
    LocationCountEmployees
}