import { Link } from "react-router-dom";

import { LocationTitleTooltip, LocationBadge } from "components/location/helpers";
import { NoticeFormTag } from "./notice-tag-helper";
import { UserTag } from "components/user/helpers";
import { NoticeVisitDatailsDrawer } from "../notice-drawer";

const noticeFormsTableColumns = [
    {
        title: 'ID', dataIndex: 'id', columnIndex:'ID', sorter: true, align: 'center', width: '5%'
    },
    {
        title: 'location', dataIndex: 'location', columnIndex:'LOCATION_ID', sorter: true, width: '20%'
    },
    {
        title: 'email', dataIndex: 'email', columnIndex:'EMAIL', sorter: true, width: '10%'
    },
    {
        title: 'message', dataIndex: 'message', columnIndex:'MESSAGE', width: '15%'
    },
    {
        title: 'type', dataIndex: 'type', columnIndex:'TYPE', sorter: true, align: 'center', width: '5%'
    },
    {
        title: 'moderator', dataIndex: 'moderator_id', columnIndex:'MODERATOR_ID', sorter: true, align: 'center', width: '15%'
    },
    {
        title: 'moderator notice', dataIndex: 'moderator_notice', width: '15%'
    },
    {
        title: 'created date', dataIndex: 'created_at', columnIndex:'CREATED_AT', sorter: true, width: '5%'
    },
    {
        title: 'status', dataIndex: 'status', columnIndex:'STATUS', sorter: true, align: 'center', width: '5%'
    },
    { 
        title: 'action', dataIndex: 'action', align: 'center', width: '5%'
    }
    
];

const noticeFormsData = ( noticeForms ) => {

    if( !noticeForms ){
        return [];
    }

    return noticeForms.map( notice => {

        let noticeModerator = '';

        // if( notice.covid_id !== null ){
        //     covidTrue = <WarningOutlined className="warning-covid" twoToneColor="#eb2f96" />;
        // } 


        if( notice.moderator !== null ){
            noticeModerator = <Link to={`/users/${ notice.moderator.id }`}>{ notice.moderator.first_name } { notice.moderator.last_name ?? '' } <p><UserTag >{ notice.moderator.role }</UserTag></p></Link>;
        } 

        return {
            key: notice.id,
            id: notice.id,
            location: <p className="d-flex"><LocationBadge>{notice.location.status }</LocationBadge><Link className="ellipsis" to={`/locations/${ notice.location_id }`}><LocationTitleTooltip location={ notice.location } /></Link></p>,
            email: notice.email,
            message: notice.message,
            type: notice.type,
            checkout_at: notice.checkout_at,
            status: <NoticeFormTag>{ notice.status }</NoticeFormTag>,
            moderator_id: noticeModerator,
            moderator_notice: notice.moderator_notice,
            created_at: notice.created_at,
            action: <NoticeVisitDatailsDrawer visit={ notice.visit } />
        };

    });

};


export{
    noticeFormsTableColumns,
    noticeFormsData
}