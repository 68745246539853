import { gql } from '@apollo/client';

export const TRANSLATION_FIND_CREATE = gql`
    mutation TranslationFindInCodeAndCreate {
        translationFindInCodeAndCreate{
            label
            message
        }
    }
`;


export const TRANSLATION_SYNC = gql`
    mutation TranslationsSync(
        $language_slug: String!, 
        $type: translationTypeEnum!
    ) {
        translationsSync(
            language_slug: $language_slug, 
            type: $type
        ){
            label
            message
            jsonFile
        }
    }
`;


export const TRANSLATION_UPDATE = gql`
    mutation TranslationUpdate(
        $child_slug: String!
        $type: String!
        $tr_group: String!
        $tr_key: String!
        $value: String
    ) {
        translationUpdate(
            child_slug: $child_slug,
            type: $type,
            tr_group: $tr_group,
            tr_key: $tr_key,
            value: $value
        ){
            label
            message
            translation{
                id
                language_slug
                type
                tr_group
                tr_key
                value
                created_at
                updated_at
            }
        }
    }
`;