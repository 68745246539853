import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { Row } from 'antd';

import CompanyUsersTableRender from './company-users-table-render';
import CompanyUsersTableFilter from './company-users-table-filter';
import { CompanyUsersAddDrawer } from '../../company-drawer';

import { GET_USERS } from "graphql/query/user-gql";

const CompanyEmployeesTab = ( { company } ) => {

    const history = useHistory();

    let match = useRouteMatch( `/companies/${ company.id }/users/page/:currentPage` );

    const basePath = `/companies/${ company.id }/users`;

    const defaultOrderBy = { column: "ROLE", order: "ASC" },
          companyId = { column: "COMPANY_ID", operator: "EQ", value: company.id },
          currentPage = match ? parseInt( match.params.currentPage ) : 1,
          perPage = 25;

    const [ searchText, setSearchText ] = useState ( "" );
    const [ orderBy, setOrderBy ] = useState ( [ defaultOrderBy ] );
    const [ filters, setFilters ] = useState( companyId );
    const [ total, setTotal ] = useState();

    // const variables = {
    //     text: searchText,
    //     first: 25,
    //     page: 1,
    //     orderBy,
    //     company: {
    //         column: 'ID',
    //         value: company.id
    //     },
    //     where: { ...filters }
    // }

    const variables = {
        text: searchText,
        first: perPage,
        page: currentPage,
        orderBy,
        where: { ...filters }
    }

    const { data : { users = {} } = {}, loading } = useQuery( GET_USERS, {
        variables,
        fetchPolicy: 'cache-and-network'
    } );
    
    const { paginatorInfo = {} } = users;

    useEffect( () => {

        if( ( paginatorInfo && !total ) ||  ( paginatorInfo.total >= 0 && paginatorInfo.total !== total ) ){
            setTotal( paginatorInfo.total );
            history.replace( basePath );
        }

    }, [ paginatorInfo, total, history, basePath ] );


    return(
        <>
            
            <div className="table-header p-3">

                <Row className="filter-header justify-content-between mb-3">
                    <h3>List Employees</h3>

                    <CompanyUsersAddDrawer company={ company } variables={ variables } />

                </Row>

                <Row className="filter-header">

                    <CompanyUsersTableFilter currentCompany={ companyId } setFilters={ setFilters } setSearchText={ setSearchText } />

                    <span className="float-right">total: { !total ? '0' : total }</span>

                </Row>
                
            </div>

            <CompanyUsersTableRender
                company={ company } 
                variables={ variables }
                data={ users?.data }
                loading={ loading }
                setOrderBy={ setOrderBy }
                defaultOrder={ defaultOrderBy }
                setFilters={ setFilters }
                pagination={{
                    pageSize: perPage,
                    total,
                    hideOnSinglePage: ( !total ) / ( total - perPage < 0 ),
                    current: currentPage,
                    onChange: ( page ) => {
                        let path = page === 1 ? `/companies/${ company.id }/users` : `/companies/${ company.id }/users/page/${ page }`
                        history.push( path );
                    }
                }}
            />

            
            {/* <Table 
                className="table-employees"
                onChange={ ( pagination, filters, { column, order } ) => {

                    setFilters( gqlWhere( filters ) );
                
                    if( column && order ){
                        
                        setOrderBy([
                            { 
                                column: column.columnIndex, 
                                order: order === "ascend" ? "ASC" : "DESC"  
                            }
                        ])

                    } else { setOrderBy( [ defaultOrderBy ] ) }

                }}
                tableLayout='fixed'
                locale={{
                    emptyText: loading ? <Skeleton active={true} paragraph={{ rows: 8 }} /> : <Empty />
                }}
                dataSource={ userEmployeesData( users?.data, company, variables) }
                columns={columns}
                size="small"
                pagination={ false }
                scroll={ { x:true } }
            /> */}

        </>
    );
}

export default CompanyEmployeesTab;