import React from 'react';
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Form, Input, Button, Select } from 'antd';

import { UserTag } from 'components/user/helpers'; 
import { noticeStatus } from 'components/notice-forms/helpers';

import { NOTICE_FORM_UPDATE } from 'graphql/mutation/notice-form-gql';

import { errorNotification, successNotification } from "components/request-result";


const { Option } = Select;

const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
};

const VisitNoticeUpdate = ( { visit } ) => {

    const [ form ] = Form.useForm();

    const [noticeFormUpdate, { loading }] = useMutation( NOTICE_FORM_UPDATE,
        {
            update(cache, { data }) {

                const {
                    noticeFormUpdate : {
                        label,
                        message,
                        noticeForm
                    }
                } = data;

                if( noticeForm.status === 'declined' ){

                    cache.modify({
                        id: cache.identify( visit ),
                        fields: {
                            covid_id(){
                                return null;
                            },
                            covid(){
                                return null;
                            }
                        },
                    });

                    cache.evict( { id: cache.identify( noticeForm )});
                    cache.gc();

                }
                
                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }

    );

    return(
        <>
            <div className="short-info-user">

                <p>
                    <strong>Visit ID:</strong>
                    <span className="first-span">
                        { visit.id }
                    </span>
                </p>

                <p>
                    <strong>Moderator:</strong>
                    <span className="first-span">
                        { visit.covid?.moderator?.first_name ? 
                            <><Link to={`/users/${ visit.covid.moderator.id }`}>{ visit.covid.moderator.first_name } { visit.covid.moderator.last_name ?? '' }</Link> </> : 
                        '' }
                        { visit.covid?.moderator ? <UserTag >{ visit.covid.moderator.role }</UserTag> : '' } 
                    </span>
                </p>

            </div>


            <Form
                key="visit-notice-update"
                form={ form }
                onFinish={(values) => {
                    noticeFormUpdate({
                        variables: {
                            id: visit.covid_id,
                            input: {
                                visit_id: visit.id,
                                ...values
                            }
                        }
                    }).catch((error) => {
                        errorNotification(error);
                    });
                }}
                {...layout} className="update-company">

                <div className="field-group">

                    <Form.Item 
                        initialValue={ visit.email }
                        name={ 'email' } 
                        label="User Email"
                    >
                        <Input disabled name="email" />
                    </Form.Item>

                </div>

                <div className="field-group">

                    <Form.Item 
                        initialValue={ visit.covid?.message ?? '' }
                        name={'message'} 
                        label="User message" 
                        rules={[
                            { message: 'Please input message' }
                        ]}
                    >
                        <Input.TextArea 
                            rows={3}
                            maxLength={ 2500 }
                            name="message" 
                            disabled
                        />
                    </Form.Item>

                </div>

                <div className="field-group">

                    <Form.Item 
                        initialValue={ visit.covid?.moderator_notice ?? '' }
                        name={'moderator_notice'} 
                        label="Moderator Notice" 
                        rules={[
                            { message: 'Please input Title' }
                        ]}
                    >
                        <Input.TextArea 
                                rows={3}
                                maxLength={ 2500 }
                                name="moderator_notice" />
                    </Form.Item>

                </div>

                <div className="field-group">

                    <Form.Item 
                        initialValue={ visit.covid?.status ?? 'new'}
                        name={['status']}
                        label="Status"
                        rules={[
                            { required: true, message: 'Please select status' }
                        ]}
                    >
                        <Select key="notice-status">
                            { noticeStatus.map( ( { text, value }, index ) => (
        
                                <Option disabled={ value === 'new' } key={ index } value={ value }>{ text }</Option>
                                
                            ))}
                        </Select>
                    </Form.Item>

                </div>

                <div className="form-actions mt-5">
                    <Button
                        loading={ loading }
                        disabled={ loading }
                        type="primary"
                        htmlType="submit"
                    >
                        Update
                    </Button>
                </div>
                

            </Form>
        </>
    );

}

export default VisitNoticeUpdate;