import React from "react";
import { Skeleton } from "antd";

import { tableHelper } from "utils";
import LocationFields from "components/location/fields";
import VisitFields from "../fields";
import { VisitDatailsDrawer } from "../visit-drawer";


const visitsTableBuilder = ({
        loading,
        rowAmount = 15,
        data = [...Array(rowAmount)],
        onlyHeader
    }) => {


    const tableData = data.map( ( row = {}, index ) => {

        // remote_addr,
        // location_id,
        // covid_id,
        // number_guests,
        // checkout_at,
        // updated_at,
        
        const {
            id,
            user_id,
            email,
            first_name,
            last_name,
            phone,
            place_id,
            created_at,
            location,
            covid
        } = row;

        return {
            key: id ? id : `row_index_${index}`,
            id : {
                title : "ID",
                value : id,
                columnIndex:'ID',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "5%"
            },
            // user_id : {
            //     title : "User ID",
            //     value : <>{ user_id ?? 'n/a' }</>,
            //     columnIndex:'USER_ID',
            //     sorter: true,
            //     align: 'center',
            //     empty: <Skeleton.Input className="table-skeleton full" active />,
            //     width: "5%"
            // },
            email : {
                title : "email",
                value : <>
                    { email ? <p>{ email }</p> : null }
                    user_id: <b>{ user_id ?? 'guest' }</b>
                    </>,
                columnIndex:'EMAIL',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "17%"
            },
            last_name : {
                title : "Full Name",
                value : <><b>{ first_name } { last_name ?? 'n/a' }</b> <br/> { phone ? phone : '' }</>,
                columnIndex:'LAST_NAME',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "18%"
            },
            location_id : {
                title : "Location",
                value : <LocationFields.TitleLink location={ location } />,
                columnIndex:'LOCATION_ID',
                sorter: true,
                empty: <Skeleton.Input className="table-skeleton full" active />,
                ellipsis: true,
                width: "25%"
            },
            place_id : {
                title : "Place",
                value : <>{ place_id ?? 'n/a' }</>,
                columnIndex:'PLACE_ID',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "15%"
            },
            covid_id : {
                title : "Covid",
                value : <VisitFields.CovidIcon covid={ covid } /> ,
                columnIndex:'COVID_ID',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "10%"
            },
            created_at : {
                title : "created date",
                value : created_at,
                columnIndex:'CREATED_AT',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "5%"
            },
            action : {
                title : "action",
                value : <VisitDatailsDrawer visit={ row } />,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "5%"
            }
        };
    });

    return tableHelper.normalize( tableData, loading, onlyHeader );

}

export default visitsTableBuilder;