import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useRouteMatch, useHistory, Link } from "react-router-dom";
import { Row } from 'antd';

import { GET_USERS } from "graphql/query/user-gql";

import { TableRender } from "utils";
import UsersTableFilter from "./users-table-filter";
import usersTableBuilder from "./users-table-builder";

const UsersTable = () => {

    const history = useHistory();

    let match = useRouteMatch( '/users/page/:currentPage' );

    const perPage = 25;
    const defaultOrderBy = { column: "CREATED_AT", order: "DESC" };
    const currentPage = match ? parseInt( match.params.currentPage ) : 1;

    const [ searchText, setSearchText ] = useState ( "" );
    const [ orderBy, setOrderBy ] = useState ( [ defaultOrderBy ] );
    const [ filters, setFilters ] = useState();
    const [ total, setTotal ] = useState();

    const { data : { users = {} } = {}, loading } = useQuery( GET_USERS, {
        variables: {
            text: searchText,
            first: perPage,
            page: currentPage,
            orderBy,
            where: { ...filters }
        },
        fetchPolicy: 'cache-and-network'
    } );
    
    const { paginatorInfo = {} } = users;

    useEffect( () => {

        if( ( paginatorInfo && !total ) || ( paginatorInfo.total >= 0 && paginatorInfo.total !== total ) ){
            setTotal( paginatorInfo.total );
            history.replace( `/users` );
        }

    }, [ paginatorInfo, total, history ] );

    return(
        <>
            <div className="table-header p-3">

                <Row className="title-header mb-4">
                    <h1>Users</h1>
               
                    <Link
                        className="ant-btn ant-btn-primary"
                        type="primary"
                        to={ '/users/create' }>
                        New Account
                    </Link>

                </Row>

                <Row className="filter-header">

                    <UsersTableFilter setSearchText={ setSearchText } setFilters={ setFilters } />

                    <span className="float-right">total: { !total ? '0' : total }</span>

                </Row>
            </div>

            <TableRender 
                tableBuilder={ usersTableBuilder }
                data={ users?.data }
                loading={ loading }
                setOrderBy={ setOrderBy }
                defaultOrder={ defaultOrderBy }
                setFilters={ setFilters }
                pagination={{
                    pageSize: perPage,
                    total,
                    hideOnSinglePage: ( !total ) / ( total - perPage < 0 ),
                    current: currentPage,
                    onChange: ( page ) => {
                        let path = page === 1 ? `/users` : `/users/page/${ page }`
                        history.push( path );
                    }
                }}
            />

        </>
    );

};

export default UsersTable;