const userStatus = [
    { text: 'new', value: 'new' },
    { text: 'active', value: 'active' },
    { text: 'block', value: 'block' },
    { text: 'trash', value: 'trash' }
];

const userRoles = [
    { text: 'developer', value: 'developer' },
    { text: 'admin', value: 'admin' },
    { text: 'owner', value: 'owner' },
    { text: 'staff', value: 'staff' },
    { text: 'visitor', value: 'visitor' }
];

export {
    userStatus,
    userRoles
}