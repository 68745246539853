import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Popconfirm } from 'antd';

import { USER_DELETE } from "graphql/mutation/user-gql";
import { errorNotification, successNotification } from "components/request-result";

const UserDeleteForm = ( { user } ) => {

    let history = useHistory();

    const [visible, setVisible] = React.useState(false);

    const [userDeleteRequest, { loading }] = useMutation( USER_DELETE,
        {
            update(cache, { data }) {

                const {
                    userDelete : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

                history.push( `/users` );

            },
            onError(error){
                errorNotification(error);
            }
        }

    );

    return(
        <>
            <Popconfirm
                title={  <>Are you sure you want to delete <b>{ user.first_name } { user?.last_name }</b></> }
                visible={ visible }
                onConfirm={ () => {
                    userDeleteRequest({
                        variables: {
                            id: [ user.id ]
                        }
                    })
                }}
                okButtonProps={ { loading } }
                onCancel={ () => setVisible( false ) }
                >
                <Button 
                    className="mb-2"
                    danger
                    type="primary"
                    onClick={ () => setVisible( true ) }
                >
                    Delete
                </Button>
            </Popconfirm>

            {/* <p>*By this action you will permanently delete the user</p> */}
        </>
    );

};

export default UserDeleteForm;