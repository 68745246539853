import { useQuery } from "@apollo/client";

import { GET_GENERAL_WIDGETS } from "graphql/query/general-widgets.qql";

const useGeneralWidgets = ( withStatus = false ) => {

    const { data : { generalWidgets } = { }, loading, error } = useQuery( GET_GENERAL_WIDGETS );

    if( withStatus ) return { generalWidgets, loading, error };

    if( generalWidgets ) return { generalWidgets } ;

    return false;
}

export default useGeneralWidgets;