import { gql } from '@apollo/client';


export const GET_NOTICE_FORMS = gql`
    query GetNoticeForms(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QueryNoticeFormsOrderByOrderByClause!],
        $where: QueryNoticeFormsWhereWhereConditions,
    ){
        noticeForms(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data{
                id
                location_id
                email
                type
                status
                message
                moderator_id
                moderator_notice
                created_at
                updated_at
                location{
                    id
                    title
                    company_id
                    type
                    status
                    company{
                        id
                        title
                        status
                        logo
                    }
                }
                user{
                    id
                    first_name
                    last_name
                    email
                    phone
                    status
                    role
                    company_id
                    company{
                        id
                        title
                        status
                        logo
                    }
                }
                moderator{
                    id
                    first_name
                    last_name
                    email
                    phone
                    status
                    role
                    company_id
                    company{
                        id
                        title
                        status
                        logo
                    }
                }
                visit{
                    id
                }
            }
        }
    }
`;

export const GET_NOTICE_FORMS_NEW = gql`
    query GetNoticeFormsNew(
        $first: Int,
        $page: Int,
        $where: QueryNoticeFormsWhereWhereConditions,
    ){
        noticeForms(
            first: $first,
            page: $page,
            where: $where
        ){
            paginatorInfo{
                total
            }
        }
    }
`;