import React from "react";
import { Switch, Route } from 'react-router-dom';

import { Pages } from "components/pages";
import UserRoute from "./users/user-route";
import CompanyRoute from "components/company/route/company-route";
import LocationsRoute from "./locations/locations-route";
import TranslatesRoute from "./translates/translates-route";
import LanguagesRoute from "./languages/language-route";
import VisitsRoute from "./visits/visits-route";
import NoticeFormsRoute from "./notice-forms/notice-forms-route";

const AppRoutes = () => {

    return(
        <Switch>
            <Route path="/" exact>
                <Pages.HomeAppPage />
            </Route>
            <Route path="/users">
                <UserRoute />
            </Route>
            <Route path="/companies">
                <CompanyRoute />
            </Route>
            <Route path="/locations">
                <LocationsRoute />
            </Route>
            <Route path="/visits">
                <VisitsRoute />
            </Route>
            <Route path="/translations">
                <TranslatesRoute />
            </Route>
            <Route path="/languages">
                <LanguagesRoute />
            </Route>
            <Route path="/notice-forms">
                <NoticeFormsRoute />
            </Route>
            <Route path="*">
                <Pages.Page404 />
            </Route>
        </Switch>
    );
};

export default AppRoutes;