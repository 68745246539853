import React from "react";
import { Skeleton } from "antd";

import { tableHelper } from "utils";
import { TranslateValueField } from "../fields";


const translatesTableBuilder = ({
        loading,
        rowAmount = 15,
        data = [...Array(rowAmount)],
        onlyHeader,
        filters = {}
    }) => {


    const tableData = data.map( ( row = {}, index ) => {

        const {
            id,
            language_slug = '', 
            type,
            tr_group,
            tr_key, 
            value,
            childTranslation
        } = row;

        const child_slug = filters.child_slug;

        return {
            key: id ? id : `row_index_${index}`,
            id : {
                title : "ID",
                value : id,
                columnIndex:'ID',
                sorter: true,
                align: 'center',
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "5%"
            },
            // language_slug : {
            //     title : "lang",
            //     value : language_slug,
            //     columnIndex:'LANGUAGE_SLUG',
            //     align: 'center',
            //     sorter: true,
            //     empty: <Skeleton.Input className="table-skeleton full" active />,
            //     width: "3%",
            //     // visible : false
            // },
            type : {
                title : "Type",
                value : type,
                columnIndex: 'TYPE',
                align: 'center',
                sorter: true,
                empty: <Skeleton.Input className="table-skeleton full" active />,
                width: "5%"
            },
            tr_group : {
                title : "Group",
                value : tr_group,
                sorter: true,
                align: 'center',
                columnIndex: 'TR_GROUP',
                empty : <Skeleton.Button active className="table-skeleton full" />,
                width: "10%"
            },
            tr_key : {
                title : "key",
                value : tr_key,
                sorter: true,
                columnIndex: 'TR_KEY',
                empty : <Skeleton.Button active className="table-skeleton full" />,
                width: "20%"
            },
            from_value : {
                title : `Translate ${language_slug}`,
                className: "from-translate",
                value : value,
                empty : <>
                    <Skeleton.Button active className="table-skeleton full" />
                    <Skeleton.Button active className="table-skeleton full" />
                </>,
                width: "30%"
            },
            to_value : {
                title : `Translate ${filters.child_slug}`,
                value : <TranslateValueField childSlug={ child_slug } translationParrent={ row } translationChild={ childTranslation ? childTranslation : {} } /> ,
                empty : <>
                    <Skeleton.Button active className="table-skeleton full" />
                    <Skeleton.Button active className="table-skeleton full" />
                </>,
                width: "30%"
            }
        };
    });

    return tableHelper.normalize( tableData, loading, onlyHeader );

}

export default translatesTableBuilder;