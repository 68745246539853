import React from "react";
import { Tabs } from 'antd';

import { LocationHeaderPage } from "../helpers";
import Forms from 'components/location/forms';
import { LocationMenuTab, LocationsVisitsTab } from "./tabs";
import LocationUploadFile from "../forms/location-upload-file";

const { TabPane } = Tabs;

const LocationTabs = ( { location } ) => {

    return(
        
        <Tabs tabPosition="left" className="single-content">

            <TabPane tab="Edit Location" key="location-tabPane-1">
                
                <LocationHeaderPage location={ location } />

                <Forms.LocationEditForm location={ location } />

            </TabPane>

            <TabPane tab="Media" key="media-tabPane-4">

                <LocationHeaderPage location={ location } />

                <LocationUploadFile location={ location } />
                
            </TabPane>

            <TabPane tab="Menu" key="menu-tabPane-3">

                <LocationHeaderPage location={ location } />

                <LocationMenuTab location={ location } />

            </TabPane>

            <TabPane tab="Visits History" key="visit-history-tabPane-3">

                <LocationHeaderPage location={ location } />

                <LocationsVisitsTab location={ location } />

            </TabPane>

            {/* <TabPane tab="Employees" disabled key="employees-tabPane-3">

                <LocationHeaderPage location={ location } />

            </TabPane>

            <TabPane tab="Places" disabled key="places-tabPane-3">

                <LocationHeaderPage location={ location } />

            </TabPane> */}

            {/* <TabPane tab="Visit History" key="visit-history-tabPane-3">

                <LocationHeaderPage location={ location } />

                <LocationsVisitsTab location={ location } />

            </TabPane> */}

            {/* <TabPane tab="Covid" disabled key="covid-tabPane-3">

                <LocationHeaderPage location={ location } />

            </TabPane> */}

        </Tabs>
    );
}

export default LocationTabs;