import React from "react";
import { Link } from "react-router-dom";

const CompanyEditLinkField = ( { company } ) => {

    return(
        <Link to={ `/companies/${ company.id }` }>edit</Link>
    );

};

export default CompanyEditLinkField;