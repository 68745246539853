import React from "react";
import { Tag, Badge } from 'antd';

const locationTagColor = ( value ) => {

    switch( value ){

        case 'draft':
            return 'orange';

        case 'active':
            return 'success';

        case 'block':
            return 'error';

        case 'closed':
            return 'default';

        default:
            return '';
    }

}

const LocationTag = (props) => {

    const { children } = props;

    return(
        <Tag color={ locationTagColor( children ) }>{ children?.toUpperCase() }</Tag>
    )
}

const LocationBadge = (props) => {

    const { children } = props;

    return(
        <Badge status={ locationTagColor( children ) }  />
    )
}

export {
    LocationTag,
    LocationBadge
}