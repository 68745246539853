import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { Pages } from "components/pages";

export default function UserRoute() {

    const { path } = useRouteMatch();
    
    return (
        <>
            <Switch>
                <Route path={`${path}`} exact>
                    <Pages.UsersPage />
                </Route>
                <Route path={`${path}/page/:id`} exact>
                    <Pages.UsersPage />
                </Route>
                <Route path={`${path}/create`} exact>
                    <Pages.UserCreatePage />
                </Route>
                <Route path={`${path}/:id`}>
                    { ( { match, history } ) => <Pages.UserEditPage match={ match } history={ history }/> }
                </Route>
            </Switch>
        </>
    );

}