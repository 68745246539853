import React from "react";
import { useHistory } from "react-router-dom";
import { PageHeader } from 'antd';

import Forms from "components/location/forms"
import { PageWrapDefault } from "components/wraps";
import { useActiveMenuItem } from "components/use-hooks";

const LocationCreatePage = () => {
    
    let history = useHistory();
    
    useActiveMenuItem( ["location-add-new"], ["locations"] );
   
    return(
        <PageWrapDefault className="page-locations-create single">
            
            <PageHeader
                className="site-page-header"
                onBack={ () => history.push( `/locations` ) }
                title="Add new Location"
            />
            <div className="single-content">
                <Forms.LocationCreateForm />
                {/* <Forms.CompanyCreateForm company={ {} } /> */}
            </div>

        </PageWrapDefault>
    );

};


export default LocationCreatePage;