import { gql } from '@apollo/client';


export const GET_ME = gql`
    query GetMe {
        me{
            id
            first_name
            last_name
            email
            phone
            language_slug
            status
            role
            avatar
            last_visit_at
            updated_at
            created_at
        }
    }
`;

export const GET_USERS = gql`
    query GetUsers(
        $text: String,
        $first: Int,
        $page: Int,
        $orderBy: [QueryUsersOrderByOrderByClause!],
        $where: QueryUsersWhereWhereConditions
    ){
        users(
            text: $text,
            first: $first,
            page: $page,
            orderBy: $orderBy,
            where: $where
        ){
            paginatorInfo{
                total
                count
                currentPage
                perPage
                lastPage
            }
            data{
                id
                first_name
                last_name
                email
                phone
                status
                role
                avatar
                language_slug
                company_id
                company{
                    id
                    title
                    status
                    logo
                }
                last_visit_at
                created_at
                updated_at
                delete_at
            }
        }
    }
`;

export const GET_USERS_AUTOCOMPLETE = gql`
    query GetUsersAutocomplete(
        $text: String
    ){
        users(
            text: $text
        ){
            data{
                id
                first_name
                last_name
                role
                company_id
            }
        }
    }
`;

export const GET_USER = gql`
    query GetUser(
        $id: ID,
    ) {
        user(
            id: $id,
        ){
            id
            first_name
            last_name
            email
            phone
            language_slug
            status
            role
            avatar
            last_visit_at
            updated_at
            created_at
        }
    }
`;