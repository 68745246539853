import React from "react";
import { Col, Row } from 'antd';

import { LocationTag } from "./location-tag-helper";
import { LocationTitleTooltip } from "./location-model-helper";
import LocationDeleteButton from "../forms/location-delete-button";

const LocationHeaderPage = ( { location } ) => {

    return(
        <Row wrap={false} className="page-heading mb-2">
            <Col flex="auto">
                <div className="">
                    <h3 className="mb-2"><LocationTag className="">{ location?.status }</LocationTag> <LocationTitleTooltip location={ location } /> </h3>
                    <p className="fs-13">create date: { location.created_at }</p>
                </div>
            </Col>
            <Col flex="none" className="ps-2">
                <LocationDeleteButton location={ location } />
            </Col>
        </Row>
    )
    
}

export {
    LocationHeaderPage
}