import React from "react";
import { Row } from 'antd';

import { NoticeAlarmTable } from "components/notice-forms";
import { useActiveMenuItem } from "components/use-hooks";

const NoticeAlarmPage = () => {

    useActiveMenuItem( ["notice-forms"] );

    return(
        <Row justify="center" className="page page-notice-forms notice-alarm fullscreen">
            <NoticeAlarmTable />
        </Row>
    );
};

export default NoticeAlarmPage;