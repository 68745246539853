import React from "react";
import { UserTag } from "../helpers";

const UserStatusField = ( { user } ) => {

    return(
        <UserTag >{ user.status }</UserTag>
    );

};

export default UserStatusField;