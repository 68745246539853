import React from "react";
import { Row } from 'antd';

import { UsersTable } from "components/user";
import { useActiveMenuItem } from "components/use-hooks";
 
import './users.scss';


const UsersPage = () => {

    useActiveMenuItem( ["users-all"], ["users"] );

    return(
        <Row justify="center" className="page page-users fullscreen">
            
            <UsersTable />
            
        </Row>
    );
};

export default UsersPage;