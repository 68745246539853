import React from "react";
import { CompanyTag } from "../helpers";

const CompanyStatusField = ( { status } ) => {

    return(
        <CompanyTag >{ status }</CompanyTag>
    );

};

export default CompanyStatusField;