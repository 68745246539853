import React from "react";
import { DatePicker, Space } from 'antd';

const { RangePicker } = DatePicker;

const VisitsFilterCreateDateBetween = ( { setFilterList, filterList } ) => {

    return (
        <div className="visits-filter-between-date">
            <span className="me-2">Between date :</span>
            <Space direction="vertical" size={12}>
                <RangePicker 
                    onChange={ ( dates, dateSrings ) => {

                        if( dateSrings[0] && dateSrings[1] ){

                            dateSrings[0] = dateSrings[0] + ' 00:00:00';
                            dateSrings[1] = dateSrings[1] + ' 23:59:59';

                            setFilterList( { 
                                ...filterList,
                                createDate : {
                                    column: "CREATED_AT",
                                    operator: "BETWEEN",
                                    value: dateSrings
                                },
                            })

                        } else {

                            setFilterList( { 
                                ...filterList,
                                createDate : {},
                            })

                        }
                        
                    }} 
                />
            </Space>
        </div>
    );

};

export default VisitsFilterCreateDateBetween;